import styled from 'styled-components';
import { Flex, Text } from 'src/components/Layout';
import { floor } from 'lodash';

type ProgressProps = {
  width?: string;
  height?: string;
  value: number;
  description?: string;
  showText?: boolean;
  color?: string;
};

export const Progress = (props: ProgressProps) => {
  const { width, height, value, showText, color, description } = props;

  return (
    <Flex direction="column" gap="lg" align="center">
      <ProgressbarContainer width={width} height={height}>
        <Progressbar value={value} height={height} color={color}>
          {showText && (
            <Text color="white" size="sm" weight={600}>
              {floor(value)}%
            </Text>
          )}
        </Progressbar>
      </ProgressbarContainer>
      {description && (
        <Text size="sm" color="gray">
          {description}
        </Text>
      )}
    </Flex>
  );
};

const ProgressbarContainer = styled.div<{ width?: string; height?: string }>`
  position: relative;
  width: ${(props) => props.width ?? '100%'};
  height: ${(props) => props.height ?? '3rem'};
  background: ${(props) => props.theme.color.grayLighter};
  border-radius: ${(props) => props.height ?? '3rem'};
`;
const Progressbar = styled.div<{ value: number; height?: string; color?: string }>`
  position: absolute;
  left: 0;
  min-width: 1rem;
  width: ${(props) => props.value}%;
  background: ${(props) => (props.color ? props.theme.color[props.color] : props.theme.color.success)};
  height: ${(props) => props.height ?? '3rem'};
  border-radius: ${(props) => props.height ?? '3rem'};
  text-align: center;
  line-height: ${(props) => props.height ?? '3rem'};
  transition: width 0.1s;
`;
