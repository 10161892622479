export type ApiResult<T = any> = {
  status?: string;
  statusCode?: number;
  error?: string;
  message?: string | string[];
  data?: T;
};

export type ApiError<T = any> = {
  data: ApiResult<T>;
};

export type UserLevelPrivileges = {};

export type UserLevel = {
  id: number;
  price_monthly: number;
  price_annual: number;
  privileges: UserLevelPrivileges;
  order: number;
  is_custom: boolean;
};

type PlanningWorkspacePlan = 'Agency' | 'Business';
type PlanTimeFrame = 'monthly' | 'annually';

export type UserWorkspace = {
  id: number;
  agency_id?: number;
  advertiser_id?: number;
  plan?: PlanningWorkspacePlan;
  plan_time_frame?: PlanTimeFrame;
  data_credits: number;
  created_at: Date;
  updated_at: Date;
};

export type User = {
  id: number;
  email: string;
  user_name: string;
  company_name: string;
  role: Role;
  is_admin: boolean;
  is_wl: boolean;
  billing?: UserBilling;
  payment?: any;
  dsp_username?: string;
  dsp_agency_id?: number;
  dsp_advertiser_id?: number;
  dsp_exclusion_rules?: any[];
  branding?: any;
  level: UserLevel;
  level_expired_at?: Date;
  workspace?: UserWorkspace;
  promo_code?: string;
};

export enum Role {
  Admin = 'admin',
  WhiteLabel = 'whiteLabel',
  Agency = 'agency',
  Advertiser = 'advertiser',
  ViewOnly = 'viewOnly',
}

export type UserBilling = {
  stripe?: {
    customerId: string;
    paymentMethodId: string;
    card: {
      brand: string;
      last4: string;
    };
  };
  autoCredit?: {
    dailyCap?: number;
    period?: 'weekly' | 'monthly';
  };
  lastUpdatedAt?: number;
};

export enum CampaignObjective {
  Awareness = 'awareness',
  ClickAndTraffic = 'click_and_traffic',
  Video = 'video',
  Conversions = 'conversions',
  Audio = 'audio',
  Leads = 'leads',
}

export enum CampaignBillingType {
  CTR = 'ctr',
  ViewabilityRate = 'viewability_rate',
  CPC = 'cpc',
  CPV = 'cpv',
  CPCV = 'cpcv',
  VCR = 'vcr',
  CPA = 'cpa',
  ROI = 'roi',
  CPL = 'cpl',
  CPCL = 'cpcl',
  MaxLeads = 'max_leads',
  MaxConversionLeads = 'max_conversion_leads',
}

export enum CampaignConversionLocationType {
  Website = 'website',
  InstantForm = 'instant_form',
  Calls = 'calls',
}

export enum CampaignDeviceType {
  Mobile = 'mobile',
  Desktop = 'desktop',
}

export enum CampaignOngoingInterval {
  Daily = 'daily',
  Weekly = 'weekly',
  Monthly = 'monthly',
  Flights = 'flights',
}

export type CampaignOngoingFlight = {
  id?: string;
  start_date?: Date;
  end_date?: Date;
  budget?: number;
};

export enum CampaignPacing {
  SpendEvenly = 'evenly',
  SpendASAP = 'asap',
}

export enum CampaignEngagement {
  NoEngagement,
  Pixel,
}

export enum CampaignDeliveryFrequencyInterval {
  Hour = 'hour',
  Day = 'day',
  Week = 'week',
  Month = 'month',
}

export enum CampaignChannel {
  DigitalOutOfHome = 'digital_out_of_home',
  CTVOrOTT = 'ctv_ott',
  Audio = 'audio',
  Video = 'video',
  Native = 'native',
  Display = 'display',
  Meta = 'meta',
}

export type AudienceGroup = {
  id?: number;
  group_id?: number;
  name?: string;
  group_inclusion_type?: 'include' | 'exclude';
  multi_value_grouping_type?: 'and' | 'or';
  rule_grouping_type?: 'and' | 'or';
};

export enum AudienceRuleResponseType {
  Text = 1,
  SingleSelect = 2,
  MultiSelect = 3,
  Custom = 5,
  YesNo = 6,
}

export type AudienceRule = {
  id?: number;
  advertiser_id?: number;
  audience_group_id?: number;
  rule_inclusion_type?: 'include' | 'exclude';
  rule_response_type?: AudienceRuleResponseType;
  rule_category_id?: number;
  rule_type_name?: string;
  rule_type_id?: number;
  rule_type_id_name?: string;
  rule_sub_type_id?: number;
  rule_sub_type_unit?: string;
  rule_value?: any;
  rule_value_name?: any;
  rule_value_additional?: any;
  multi_value_grouping_type?: 'or' | 'and';
  open_data_provider_modal?: boolean;
};

export type AudienceFile = {
  file: string;
  file_name: string;
  file_url: string;
};

export enum CreativeType {
  BannerAd = 'file',
  AdTag = 'tag',
  AudioAd = 'audio',
  VideoAd = 'video',
  NativeAd = 'native',
  HTML5Ad = 'html5',
  MetaSingleImageVideoAd = 'meta_single_image_video',
}

export type CreativeFile = {
  file: string;
  file_name: string;
  file_url: string;
};
