import { TOKENS } from 'src/design';
import styled, { css, keyframes } from 'styled-components';
import { SVGS } from './svgs';

type IconSize = 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'xxxl';

type IconColor = keyof typeof TOKENS.color;

export type IconProps = {
  type: keyof typeof SVGS;
  size?: IconSize | (string & {});
  color?: IconColor;
  secondaryColor?: IconColor;
  clickable?: boolean;
  onClick?: () => void;
  badgeNumber?: number;
};

const SIZE_MAPPING: Record<IconSize, number> = {
  xxs: 12,
  xs: 14,
  sm: 16,
  md: 24,
  lg: 32,
  xl: 48,
  xxl: 64,
  xxxl: 72,
};

export const Icon = (props: IconProps) => {
  const { type, size = 'md', color, secondaryColor, clickable, onClick, badgeNumber } = props;

  const finalSize = size in SIZE_MAPPING ? SIZE_MAPPING[size as IconSize] : size;

  return (
    <IconContainer>
      <StyledSvg
        width={finalSize}
        height={finalSize}
        color={color}
        secondaryColor={secondaryColor}
        $clickable={clickable}
        onClick={onClick}
        $loading={type === 'loading'}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        {SVGS[type]}
      </StyledSvg>
      {Boolean(badgeNumber) && <Badge>{badgeNumber}</Badge>}
    </IconContainer>
  );
};

const rotate = keyframes`
  0% { 
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Badge = styled.span`
  position: absolute;
  top: -10px;
  right: -10px;
  width: 18px;
  height: 18px;
  text-align: center;
  line-height: 18px;
  border-radius: 50%;
  background-color: red;
  color: white;
  font-size: 10px;
`;

const IconContainer = styled.div`
  position: relative;
  display: inline-block;
  line-height: 0;
`;

const StyledSvg = styled.svg<
  Pick<IconProps, 'color' | 'secondaryColor'> & { $loading?: boolean; $clickable?: boolean }
>`
  fill: ${(props) => (props.color ? props.theme.color[props.color] : 'currentColor')};

  .secondary {
    fill: ${(props) => (props.secondaryColor ? TOKENS.color[props.secondaryColor] : 'transparent')};
  }

  ${(props) =>
    props.$loading
      ? css`
          animation: infinite 1s ${rotate} linear;
        `
      : ''};

  ${(props) =>
    props.$clickable
      ? css`
          cursor: pointer;
        `
      : ''};
`;
