import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { jsonToCSV, readString } from 'react-papaparse';
import {
  Action,
  Button,
  ControlledFile,
  ControlledRadioGroup,
  ControlledRadioGroupOption,
  Form,
  FormItem,
} from 'src/components/Form';
import { Flex, Spacing, Text } from 'src/components/Layout';
import { Modal } from 'src/components/Modal';
import { useToast } from 'src/hooks';
import { growthApi } from 'src/services';
import { exportCSV, getApiErrorMessage } from 'src/utils';
import { BulkUploadStatusModal, RowStatus } from './BulkUploadStatusModal';
import {
  BEESWAX_BULK_UPLOAD_CREATE_CAMPAIGN_COLUMNS,
  BEESWAX_BULK_UPLOAD_UPDATE_CAMPAIGN_COLUMNS,
  BEESWAX_BULK_UPLOAD_UPDATE_CREATIVE_COLUMNS,
} from './constants';

const { useBulkUploadBeeswaxCampaignsMutation, useBulkUploadBeeswaxCreativesMutation } = growthApi;

enum BulkUploadCampaignsType {
  Create = 'create',
  Update = 'update',
}

const BULK_UPLOAD_CAMPAIGNS_TYPES_OPTION: ControlledRadioGroupOption[] = [
  {
    label: 'Create',
    value: BulkUploadCampaignsType.Create,
  },
  {
    label: 'Update',
    value: BulkUploadCampaignsType.Update,
  },
];

type BulkUploadCampaignsFormValues = {
  type: BulkUploadCampaignsType;
  file: File;
};

type BulkUploadCreativesFormValues = {
  file: File;
};

export const BulkUploadBeeswax = () => {
  const [isBulkUploadCampaignsOpen, setIsBulkUploadCampaignsOpen] = useState(false);
  const [bulkUploadCampaigns, { isLoading: isBulkUploadCampaignsLoading }] = useBulkUploadBeeswaxCampaignsMutation();
  const {
    watch: bulkUploadCampaignsWatch,
    control: bulkUploadCampaignsControl,
    handleSubmit: handleSubmitBulkUploadCampaigns,
  } = useForm<BulkUploadCampaignsFormValues>({
    defaultValues: {
      type: BulkUploadCampaignsType.Create,
    },
  });
  const bulkUploadCampaignsValues = bulkUploadCampaignsWatch();
  const [isBulkUploadCreativesOpen, setIsBulkUploadCreativesOpen] = useState(false);
  const [bulkUploadCreatives, { isLoading: isBulkUploadCreativesLoading }] = useBulkUploadBeeswaxCreativesMutation();
  const { control: bulkUploadCreativesControl, handleSubmit: handleSubmitBulkUploadCreatives } =
    useForm<BulkUploadCreativesFormValues>();
  const { showErrorToast } = useToast();
  const [isBulkUploadStatusOpen, setIsBulkUploadStatusOpen] = useState(false);
  const [bulkUploadTitle, setBulkUploadTitle] = useState<string>('');
  const [bulkUploadColumns, setBulkUploadColumns] = useState<string[]>([]);
  const [bulkUploadData, setBulkUploadData] = useState<any[]>([]);
  const [bulkUploadRowStatus, setBulkUploadRowStatus] = useState<Record<number, RowStatus>>({});

  const onBulkUploadCampaigns = async (data: BulkUploadCampaignsFormValues) => {
    if (!data.file) {
      showErrorToast('Please select bulk upload file');
      return;
    }
    readString(await data.file.text(), {
      header: true,
      complete: async (result: any) => {
        setIsBulkUploadCampaignsOpen(false);
        setIsBulkUploadStatusOpen(true);
        setBulkUploadTitle(
          `Bulk Upload ${data.type === BulkUploadCampaignsType.Create ? 'Create' : 'Update'} Campaigns`,
        );
        setBulkUploadColumns(result.meta.fields);
        setBulkUploadData(result.data.map((row: any, index: number) => ({ ...row, index })));
        setBulkUploadRowStatus({});
        for (let i = 0; i < result.data.length; i++) {
          try {
            setBulkUploadRowStatus((prev) => ({ ...prev, [i]: { status: 'processing' } }));
            await bulkUploadCampaigns({
              row: result.data[i],
              type: data.type,
            }).unwrap();
            setBulkUploadRowStatus((prev) => ({ ...prev, [i]: { status: 'success' } }));
          } catch (error) {
            setBulkUploadRowStatus((prev) => ({
              ...prev,
              [i]: { status: 'error', message: getApiErrorMessage(error).toString() },
            }));
          }
        }
      },
    });
  };

  const onBulkUploadCreatives = async (data: BulkUploadCreativesFormValues) => {
    if (!data.file) {
      showErrorToast('Please select bulk upload file');
      return;
    }
    readString(await data.file.text(), {
      header: true,
      complete: async (result: any) => {
        setIsBulkUploadCreativesOpen(false);
        setIsBulkUploadStatusOpen(true);
        setBulkUploadTitle(`Bulk Upload Update Creatives`);
        setBulkUploadColumns(result.meta.fields);
        setBulkUploadData(result.data.map((row: any, index: number) => ({ ...row, index })));
        setBulkUploadRowStatus({});
        for (let i = 0; i < result.data.length; i++) {
          try {
            setBulkUploadRowStatus((prev) => ({ ...prev, [i]: { status: 'processing' } }));
            await bulkUploadCreatives({
              row: result.data[i],
            }).unwrap();
            setBulkUploadRowStatus((prev) => ({ ...prev, [i]: { status: 'success' } }));
          } catch (error) {
            setBulkUploadRowStatus((prev) => ({
              ...prev,
              [i]: { status: 'error', message: getApiErrorMessage(error).toString() },
            }));
          }
        }
      },
    });
  };

  const downloadBulkUploadCampaignsTemplate = () => {
    let templateFields = [];
    switch (bulkUploadCampaignsValues.type) {
      case BulkUploadCampaignsType.Create:
        templateFields = BEESWAX_BULK_UPLOAD_CREATE_CAMPAIGN_COLUMNS;
        break;
      case BulkUploadCampaignsType.Update:
        templateFields = BEESWAX_BULK_UPLOAD_UPDATE_CAMPAIGN_COLUMNS;
        break;
    }
    exportCSV(
      `bulk_upload_beeswax_campaigns_${bulkUploadCampaignsValues.type}_template.csv`,
      jsonToCSV({ fields: templateFields, data: templateFields.map(() => '') }),
    );
  };

  const downloadBulkUploadCreativesTemplate = () => {
    const templateFields = BEESWAX_BULK_UPLOAD_UPDATE_CREATIVE_COLUMNS;
    exportCSV(
      'bulk_upload_beeswax_creatives_template.csv',
      jsonToCSV({
        fields: templateFields,
        data: templateFields.map(() => ''),
      }),
    );
  };

  return (
    <>
      <Flex direction="column" gap="lg">
        <Button various="primary" width="24rem" onClick={() => setIsBulkUploadCampaignsOpen(true)}>
          BULK UPLOAD CAMPAIGNS
        </Button>
        <Button various="secondary" width="24rem" onClick={() => setIsBulkUploadCreativesOpen(true)}>
          BULK UPDATE CREATIVES
        </Button>
      </Flex>
      <Modal
        isOpen={isBulkUploadCampaignsOpen}
        title="Bulk Upload Campaigns"
        onConfirm={handleSubmitBulkUploadCampaigns(onBulkUploadCampaigns)}
        onClose={() => setIsBulkUploadCampaignsOpen(false)}
        isLoading={isBulkUploadCampaignsLoading}
      >
        <Form>
          <FormItem required label="Bulk upload type">
            <ControlledRadioGroup
              name="type"
              control={bulkUploadCampaignsControl}
              options={BULK_UPLOAD_CAMPAIGNS_TYPES_OPTION}
            />
          </FormItem>
          <FormItem required label="Bulk upload file">
            <ControlledFile name="file" control={bulkUploadCampaignsControl} />
            <Spacing />
            <Flex justify="center" gap="lg">
              <Action onClick={downloadBulkUploadCampaignsTemplate}>
                <Text size="sm">Download Template</Text>
              </Action>
            </Flex>
          </FormItem>
        </Form>
      </Modal>
      <Modal
        isOpen={isBulkUploadCreativesOpen}
        title="Bulk Update Creatives"
        onConfirm={handleSubmitBulkUploadCreatives(onBulkUploadCreatives)}
        onClose={() => setIsBulkUploadCreativesOpen(false)}
        isLoading={isBulkUploadCreativesLoading}
      >
        <Form>
          <FormItem required label="Bulk upload file">
            <ControlledFile name="file" control={bulkUploadCreativesControl} />
            <Spacing />
            <Flex justify="center" gap="lg">
              <Action onClick={downloadBulkUploadCreativesTemplate}>
                <Text size="sm">Download Template</Text>
              </Action>
            </Flex>
          </FormItem>
        </Form>
      </Modal>
      <BulkUploadStatusModal
        isOpen={isBulkUploadStatusOpen}
        onClose={() => setIsBulkUploadStatusOpen(false)}
        title={bulkUploadTitle}
        columns={bulkUploadColumns}
        data={bulkUploadData}
        rowStatus={bulkUploadRowStatus}
      />
    </>
  );
};
