import { DataTable } from 'src/components/DataTable';
import { Action, Button, ControlledInput } from 'src/components/Form';
import { Flex, Spacing, Text } from 'src/components/Layout';
import { PageTemplate } from 'src/components/Template';
import { Link } from 'react-router-dom';
import { growthApi } from 'src/services';
import { formatDate } from 'src/utils';
import { useForm } from 'react-hook-form';
import { AgencySelect } from 'src/components/AgencySelect';
import { pick } from 'lodash';
import { ArchiveNew } from 'src/components/Archive';
import { AdvertiserSelect } from 'src/components/AdvertiserSelect';
import { useCommonPersistentFilters, useDataTable, useRole } from 'src/hooks';
import { Role } from 'src/types';

const { useAudiencesQuery, useArchiveAudienceMutation } = growthApi;

type AudiencesFormValues = {
  agency_id?: number;
  advertiser_id?: number;
  search?: string;
};

export const Audiences = () => {
  const { canAccessAgency, isViewOnly } = useRole();
  const [archiveAudience, { isLoading: isArchiving }] = useArchiveAudienceMutation();
  const { watch, setValue, control, reset } = useForm<AudiencesFormValues>();
  const values = watch();
  useCommonPersistentFilters({ values, setValues: reset });

  const { data, isFetching, error, refetch } = useAudiencesQuery(pick(values, ['agency_id', 'advertiser_id']));

  const onArchive = async (row: any) => {
    await archiveAudience({
      id: row.id,
    }).unwrap();
    refetch();
  };

  const { dataTableProps } = useDataTable({
    data: data?.data,
    isLoading: isFetching,
    error,
    defaultSort: {
      key: 'id',
      direction: 'desc',
    },
    search: values.search,
    searchKeys: ['id', 'audience_name', 'advertiser_name'],
    sortNumberKeys: ['id'],
    columns: [
      { header: 'ID', accessor: 'id', sortable: true },
      { header: 'Audience Name', accessor: 'audience_name', sortable: true },
      { header: 'Advertiser', accessor: 'advertiser_name', sortable: true },
      { header: 'Created At', accessor: 'created_at', sortable: true, render: formatDate },
      {
        header: '',
        accessor: 'action',
        width: '3rem',
        when: (user) => user?.role !== Role.ViewOnly,
        render: (_, row) => (
          <Flex gap="md">
            <Link to={`/activate/audiences/edit?copy=${row.id}`}>
              <Action>Copy</Action>
            </Link>
            <Link to={`/activate/audiences/edit?id=${row.id}`}>
              <Action>Edit</Action>
            </Link>
            <ArchiveNew onArchive={() => onArchive(row)} isArchiving={isArchiving} />
          </Flex>
        ),
      },
    ],
  });

  return (
    <PageTemplate>
      <Flex justify="space-between" align="center">
        <Flex align="center" gap="lg">
          <Text size="xxl" weight={700}>
            Audiences
          </Text>
          {canAccessAgency && (
            <>
              <AgencySelect
                prefix="Agency:"
                name="agency_id"
                control={control}
                withAll
                width="22rem"
                onValueChange={() => {
                  setValue('advertiser_id', null);
                }}
              />
              <AdvertiserSelect
                agencyId={values.agency_id}
                prefix="Advertiser:"
                name="advertiser_id"
                control={control}
                withAll
                width="22rem"
              />
            </>
          )}
          <ControlledInput prefix="Search: " name="search" control={control} placeholder="Keyword" width="22rem" />
        </Flex>
        {!isViewOnly && (
          <Flex gap="xxl">
            <Link to={'/activate/audiences/new'}>
              <Button width="16rem" shadow>
                NEW AUDIENCE
              </Button>
            </Link>
          </Flex>
        )}
      </Flex>
      <Spacing size="xl" />
      <DataTable {...dataTableProps} />
    </PageTemplate>
  );
};
