import { TOKENS } from 'src/design';
import { useEffect } from 'react';
import styled from 'styled-components';

const TOAST_TYPE_COLORS = {
  success: TOKENS.color.success,
  error: TOKENS.color.error,
  info: TOKENS.color.info,
};

const TOAST_DEFAULT_DURING = 5000;

export type ToastProps = {
  id: string;
  type: keyof typeof TOAST_TYPE_COLORS;
  message?: string | string[];
  during?: number;
  onDismiss?: (id: string) => void;
};

export const Toast = (props: ToastProps) => {
  const { id, type, message, during = TOAST_DEFAULT_DURING, onDismiss } = props;

  useEffect(() => {
    const timeout = setTimeout(() => {
      onDismiss?.(id);
    }, during);
    return () => {
      clearTimeout(timeout);
    };
  }, [during, id, onDismiss]);

  return (
    <StyledToast type={type}>
      {Array.isArray(message) ? message.map((item) => <div key={item}>{item}</div>) : message}
    </StyledToast>
  );
};

const StyledToast = styled.div<{ type: ToastProps['type'] }>`
  padding: 1.2rem 2.4rem;
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  color: white;
  background: ${(props) => TOAST_TYPE_COLORS[props.type]};
  border-radius: 0.6rem;
  font-size: 1.4rem;
  box-shadow: ${TOKENS.shadow.default};
`;
