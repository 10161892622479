import { Modal } from 'src/components/Modal';
import { useDataTable } from 'src/hooks';
import { DataTable } from 'src/components/DataTable';
import { Spacing, Text } from 'src/components/Layout';
import styled from 'styled-components';

export type RowStatus = {
  status: 'processing' | 'success' | 'error';
  message?: string;
};

export type BulkUploadStatusModalProps = {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  columns: string[];
  data: any[];
  rowStatus: Record<number, RowStatus>;
};

export const BulkUploadStatusModal = (props: BulkUploadStatusModalProps) => {
  const { isOpen, onClose, title, columns, data, rowStatus } = props;

  const { dataTableProps } = useDataTable({
    columns: [
      {
        header: 'Status',
        accessor: '_status',
        render: (_, row) => {
          const status = rowStatus[row.index];
          if (!status) {
            return (
              <Text color="grey" weight={500}>
                Waiting...
              </Text>
            );
          }
          switch (status.status) {
            case 'processing':
              return '🚀';
            case 'success':
              return '✅';
            case 'error':
              return <Text color="red">❗️ {status.message}</Text>;
          }
        },
      },
      {
        header: 'No.',
        accessor: 'index',
        render: (_, row) => row.index + 1,
      },
      ...columns.map((column) => ({ header: column, accessor: column })),
    ],
    data,
  });

  return (
    <Modal
      width="75%"
      title={title}
      isOpen={isOpen}
      onClose={onClose}
      disableConfirm
      disableCancel={!Object.values(rowStatus).every((row) => ['success', 'error'].includes(row.status))}
      cancelText="CLOSE"
    >
      <Text align="center" color="primary" weight={500}>
        {`${Object.values(rowStatus).filter((row) => row.status === 'success').length} rows have been uploaded. ${
          Object.values(rowStatus).filter((row) => row.status === 'error').length
        } rows have errors.`}
      </Text>
      <Spacing size="xl" />
      <DataTableContainer>
        <DataTable {...dataTableProps} scroll />
      </DataTableContainer>
    </Modal>
  );
};

const DataTableContainer = styled.div`
  max-height: 500px;
  overflow: auto;
`;
