import { compact, get, isString, orderBy } from 'lodash';
import { useMemo, useState, useEffect } from 'react';
import { Column, DEFAULT_PAGE, DEFAULT_PAGE_SIZE, DataTableExportType, Sort } from 'src/components/DataTable';
import { exportCSV, getApiErrorMessage } from 'src/utils';
import { useDeepEffect } from './useDeepEffect';
import { useRole } from './useRole';
import { Checkbox } from 'src/components/Form';
import { format } from 'date-fns';
import { jsonToCSV } from 'react-papaparse';
import { useToast } from './useToast';

const LOCAL_STORAGE_KEY = 'data-table';

const getDataTableSetting = (name?: string) => {
  if (!name) {
    return null;
  }
  const setting = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) || '{}');
  return setting[name];
};

const updateDataTableSetting = (name: string, updates: any) => {
  const setting = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) || '{}');
  setting[name] = {
    ...setting[name],
    ...updates,
  };
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(setting));
};

export type UseDataTableOptions = {
  name?: string;
  idKey?: string;
  defaultCustomizeColumns?: string[];
  columns?: Column[];
  data?: any[];
  error?: any;
  isLoading?: boolean;
  defaultPageSize?: number;
  search?: string;
  searchKeys?: any[];
  customSearch?: (search: string, row: any) => boolean;
  defaultSort?: Sort;
  sortNumberKeys?: any[];
  enableSelection?: boolean;
  enableTotal?: boolean;
  onVisibleDataChange?: () => void | Promise<void>;
  onChangeRowStatus?: (id: any, field: string, value: any, row?: any) => void | Promise<void>;
  onExpend?: (id: any) => any | Promise<any>;
};

export const useDataTable = (options: UseDataTableOptions) => {
  const {
    name,
    idKey,
    defaultCustomizeColumns,
    columns,
    data,
    error,
    isLoading,
    defaultPageSize = DEFAULT_PAGE_SIZE,
    search,
    searchKeys,
    customSearch,
    defaultSort,
    sortNumberKeys,
    enableSelection,
    enableTotal,
    onVisibleDataChange,
    onChangeRowStatus,
    onExpend,
  } = options;

  const { user } = useRole();
  const [page, setPage] = useState<number>(DEFAULT_PAGE);
  const [pageSize, setPageSize] = useState<number>(defaultPageSize);
  const [sort, setSort] = useState<Sort>(defaultSort || {});
  const [rowStatus, setRowStatus] = useState<Record<string, any>>({});
  const [selectedRow, setSelectedRow] = useState<any>();
  const [expendIds, setExpendIds] = useState<any[]>([]);
  const [expendLoadingIds, setExpendLoadingIds] = useState<any[]>([]);
  const [expendData, setExpendData] = useState<Record<any, any>>({});
  const [selection, setSelection] = useState<any[]>([]);
  const [customizeColumns, setCustomizeColumns] = useState<string[]>(defaultCustomizeColumns);
  const { showErrorToast } = useToast();

  useEffect(() => {
    const setting = getDataTableSetting(name);
    if (setting?.customizeColumns) {
      setCustomizeColumns(setting.customizeColumns);
    }
  }, [name]);

  const resultData = useMemo(() => {
    // combine data & row status
    let result = (data || []).map((item) => ({
      ...item,
      ...rowStatus[get(item, idKey)],
    }));
    // search filter
    if (search) {
      result = result.filter((row) => {
        let isMatchedBySearchKeys = false;
        let isMatchedByCustomSearch = false;
        if (searchKeys) {
          isMatchedBySearchKeys = compact(searchKeys.map((searchKey) => get(row, searchKey))).some((value) => {
            return String(value).toLowerCase().includes(search.toLowerCase());
          });
        }
        if (customSearch) {
          isMatchedByCustomSearch = customSearch(search, row);
        }
        return isMatchedBySearchKeys || isMatchedByCustomSearch;
      });
    }
    // order
    if (sort) {
      result = orderBy(
        result,
        (item) => (sortNumberKeys?.includes(sort.key) ? Number(get(item, sort.key)) || 0 : get(item, sort.key) ?? ''),
        sort.direction,
      );
    }
    return result;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, idKey, search, searchKeys, customSearch, sort, sortNumberKeys]);

  const visibleData = useMemo(() => {
    return resultData.filter((_, index) => index >= (page - 1) * pageSize && index < page * pageSize);
  }, [resultData, page, pageSize]);

  // clear selection when visible data change
  useDeepEffect(() => {
    setSelection([]);
    onVisibleDataChange?.();
  }, [visibleData]);

  // set page to 1 when sort & search change
  useEffect(() => {
    setPage(1);
  }, [sort, search]);

  const visibleColumns = useMemo(() => {
    return columns.filter((column) => {
      if (column.when && !column.when(user)) {
        return false;
      }
      return true;
    });
  }, [columns, user]);

  const finalColumns = useMemo(() => {
    const selectedColumns = visibleColumns.filter((column) => {
      if (
        isString(column.header) &&
        column.customizeGroup &&
        customizeColumns &&
        !customizeColumns.includes(column.header)
      ) {
        return false;
      }
      return true;
    });
    if (enableSelection) {
      selectedColumns.unshift({
        header: () => {
          const isSelected = selection.length > 0 && selection.length === visibleData?.length;
          return (
            <Checkbox
              value={isSelected}
              onChange={() => {
                if (isSelected) {
                  setSelection?.([]);
                } else {
                  setSelection?.(visibleData?.map((row) => get(row, idKey)));
                }
              }}
            />
          );
        },
        width: '2rem',
        accessor: '_selection',
        render: (_, row) => {
          const isSelected = selection.includes(get(row, idKey));
          return (
            <Checkbox
              value={isSelected}
              onChange={() => {
                if (isSelected) {
                  setSelection?.(selection.filter((item) => item !== get(row, idKey)));
                } else {
                  setSelection?.([...selection, get(row, idKey)]);
                }
              }}
            />
          );
        },
        export: false,
      });
    }
    return selectedColumns;
  }, [customizeColumns, enableSelection, idKey, selection, visibleColumns, visibleData]);

  const changeRowStatus = async (id: any, field: string, value: any, row?: any) => {
    try {
      await onChangeRowStatus?.(id, field, value, row);
      setRowStatus((rowStatus) => ({
        ...rowStatus,
        [id]: {
          ...rowStatus[id],
          [field]: value,
        },
      }));
    } catch (error) {
      showErrorToast(getApiErrorMessage(error));
    }
  };

  const isExpend = (id: any) => {
    return expendIds.includes(id);
  };

  const isExpendLoading = (id: any) => {
    return expendLoadingIds.includes(id);
  };

  const triggerExpend = async (id: any) => {
    // if id is expend, close it, else load expend data
    if (expendIds.includes(id)) {
      setExpendIds((expendIds) => expendIds.filter((expendId) => expendId !== id));
    } else {
      if (!expendData[id]) {
        setExpendLoadingIds((expendLoadingIds) => [...expendLoadingIds, id]);
        const newExpendData = await onExpend?.(id);
        setExpendLoadingIds((expendLoadingIds) => expendLoadingIds.filter((loadingId) => loadingId !== id));
        setExpendData((expendData) => ({
          ...expendData,
          [id]: newExpendData,
        }));
      }
      setExpendIds((expendIds) => [...expendIds, id]);
    }
  };

  const getExpendData = () => {
    let result: any = [];
    expendIds.forEach((expendId) => (result = [...result, ...expendData[expendId]]));
    return result;
  };

  const clearExpendData = () => {
    setExpendIds([]);
    setExpendData({});
  };

  // clear expend data, if data is change
  useEffect(() => {
    clearExpendData();
  }, [data]);

  const onExport = (type: DataTableExportType) => {
    const exportColumns = finalColumns.filter((column) => column.export !== false);
    const filename = `${name}-${format(new Date(), 'MM-dd-yyyy')}`;
    switch (type) {
      case DataTableExportType.Csv:
        const csv = jsonToCSV({
          fields: exportColumns.map((column) => column.header) as never,
          data: resultData.map((row) => {
            const parsedRow = {};
            exportColumns.forEach((column) => {
              const value = get(row, column.accessor);
              if (isString(column.header)) {
                parsedRow[column.header] = column.export
                  ? column.export(value, row)
                  : column.render
                  ? column.render(value, row)
                  : value;
              }
            });
            return parsedRow;
          }),
        });
        exportCSV(`${filename}.csv`, csv);
        break;
    }
  };

  const onCustomizeColumnsChange = (customizeColumns: string[]) => {
    setCustomizeColumns(customizeColumns);
    updateDataTableSetting(name, { customizeColumns });
  };

  return {
    // page
    setPage,
    setPageSize,
    // selection
    selection,
    setSelection,
    // selected row
    selectedRow,
    setSelectedRow,
    // row status
    rowStatus,
    changeRowStatus,
    // expend
    isExpend,
    isExpendLoading,
    triggerExpend,
    getExpendData,
    // generate data table props
    dataTableProps: {
      idKey,
      columns: finalColumns,
      visibleData,
      data: resultData,
      isLoading,
      error: getApiErrorMessage(error),
      total: resultData.length,
      page,
      onPageChange: setPage,
      pageSize,
      sort,
      onSortChange: setSort,
      enableTotal,
      expendIds,
      expendData,
    },
    // generate data table export columns props
    dataTableExportProps: {
      onExport,
    },
    // generate data table customize columns props
    dataTableCustomizeColumnsProps: {
      columns: visibleColumns,
      customizeColumns,
      onCustomizeColumnsChange,
    },
  };
};
