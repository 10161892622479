import { DataTable } from 'src/components/DataTable';
import { Button, ControlledInput, Switch, Tab, Tabs } from 'src/components/Form';
import { Flex, Spacing, Text } from 'src/components/Layout';
import { PageTemplate } from 'src/components/Template';
import { Link } from 'react-router-dom';
import { growthApi } from 'src/services';
import { useForm } from 'react-hook-form';
import { AgencySelect } from 'src/components/AgencySelect';
import { AdvertiserSelect } from 'src/components/AdvertiserSelect';
import { ArchiveNew } from 'src/components/Archive';
import { useCommonPersistentFilters, useDataTable, useRole, useToast } from 'src/hooks';
import { useMemo, useState } from 'react';
import { isEmpty, keyBy, pick } from 'lodash';
import { downloadFile, formatOnlyDate } from 'src/utils';
import { Role } from 'src/types';
import { CreativePreviewNew } from 'src/components/CreativePreview';
import { TOKENS } from 'src/design';
import styled from 'styled-components';

const { useCreativesQuery, useSetCreativeActiveMutation, useArchiveCreativeMutation } = growthApi;

enum CreativesTab {
  All = 'all',
  Banner = 'file',
  AdTag = 'tag',
  Html5 = 'html5',
  Audio = 'audio',
  Video = 'video',
  Native = 'native',
  MetaSingleImageVideo = 'meta_single_image_video',
}

const CREATIVES_TABS: Tab[] = [
  { label: 'All', value: CreativesTab.All },
  { label: 'Banner', value: CreativesTab.Banner },
  { label: 'Ad tag', value: CreativesTab.AdTag },
  { label: 'Html5', value: CreativesTab.Html5 },
  { label: 'Audio', value: CreativesTab.Audio },
  { label: 'Video', value: CreativesTab.Video },
  { label: 'Native', value: CreativesTab.Native },
  { label: 'Meta single image/video', value: CreativesTab.MetaSingleImageVideo },
];
const CREATIVES_TABS_BY_VALUE = keyBy(CREATIVES_TABS, 'value');

type CreativesFormValues = {
  agency_id?: number;
  advertiser_id?: number;
  search?: string;
};

export const Creatives = () => {
  const { canAccessAgency, isViewOnly } = useRole();
  const { watch, control, setValue, reset } = useForm<CreativesFormValues>();
  const values = watch();
  useCommonPersistentFilters({ values, setValues: reset });
  const [tab, setTab] = useState(CreativesTab.All);
  const { showErrorToast } = useToast();

  const { data, isFetching, error, refetch } = useCreativesQuery({
    ...pick(values, ['agency_id', 'advertiser_id']),
  });

  const tabData = useMemo(() => {
    if (tab && tab !== CreativesTab.All) {
      return data?.data?.filter((row: any) => row.creative_type === tab);
    }
    return data?.data;
  }, [data, tab]);

  const [archiveCreative, { isLoading: isArchiving }] = useArchiveCreativeMutation();
  const onArchive = async (row: any) => {
    await archiveCreative({
      id: row.id,
    }).unwrap();
    refetch();
  };
  const [setCreativeActive] = useSetCreativeActiveMutation();
  const { dataTableProps, rowStatus, changeRowStatus, selection } = useDataTable({
    idKey: 'id',
    data: tabData,
    isLoading: isFetching,
    error,
    search: values.search,
    searchKeys: ['id', 'creative_name', 'advertiser_name'],
    defaultSort: {
      key: 'id',
      direction: 'desc',
    },
    sortNumberKeys: ['id', 'is_active'],
    onChangeRowStatus: async (id: number, field: string, value: any) => {
      switch (field) {
        case 'is_active':
          await setCreativeActive({
            id,
            is_active: value,
          }).unwrap();
          break;
      }
    },
    enableSelection: true,
    columns: [
      {
        header: 'ID',
        accessor: 'id',
        sortable: true,
        render: (value, row) => (row.id && row.id !== value && row.id !== '---' ? `${value} / ${row.id}` : value),
      },
      { header: 'Creative Name', accessor: 'creative_name', width: '15rem', sortable: true },
      { header: 'Advertiser', accessor: 'advertiser_name', width: '12rem', sortable: true },
      {
        header: 'Type',
        accessor: 'creative_type',
        render: (value) => (value ? CREATIVES_TABS_BY_VALUE[value]?.label : ''),
      },
      { header: 'Created At', accessor: 'created_at', sortable: true, render: formatOnlyDate },
      {
        header: 'Preview',
        accessor: 'file.file_url',
        render: (value) =>
          value ? (
            <Flex direction="column" gap="sm">
              <CreativePreviewNew fileUrl={value} />
            </Flex>
          ) : (
            '-'
          ),
      },
      {
        header: 'On/Off',
        accessor: 'is_active',
        render: (value, row) => {
          return (
            <Switch
              value={rowStatus[row.id]?.['is_active'] ?? value}
              onChange={(status) => changeRowStatus(row.id, 'is_active', status, row)}
            />
          );
        },
        when: (user) => user?.role !== Role.ViewOnly,
        sortable: true,
      },
      {
        header: '',
        accessor: 'action',
        width: '3rem',
        when: (user) => user?.role !== Role.ViewOnly,
        render: (_, row) => {
          return (
            <Flex gap="md">
              <Link to={`/activate/creatives/edit?copy=${row.id}`}>
                <Text size="xs" weight={600} color="primary" clickable>
                  Copy
                </Text>
              </Link>
              <Link to={`/activate/creatives/edit?id=${row.id}`}>
                <Text size="xs" weight={600} color="primary" clickable>
                  Edit
                </Text>
              </Link>
              <ArchiveNew onArchive={() => onArchive(row)} isArchiving={isArchiving} />
            </Flex>
          );
        },
      },
    ],
  });

  const onDownload = () => {
    if (isEmpty(selection)) {
      showErrorToast('Please select creatives to download');
      return;
    }
    const filename = 'creatives.zip';
    const downloadUrl = `${process.env.REACT_APP_GROWTH_API_BASE_URL}dsp/creatives/download?ids=${selection.join(',')}`;
    downloadFile(filename, downloadUrl);
  };

  return (
    <PageTemplate>
      <Flex justify="space-between" align="center">
        <Text size="xxl" weight={700}>
          Creatives
        </Text>
        {!isViewOnly && (
          <Flex gap="xxl">
            <Link to="/activate/creatives/new">
              <Button width="16rem" shadow>
                NEW CREATIVE
              </Button>
            </Link>
          </Flex>
        )}
      </Flex>
      {canAccessAgency && (
        <>
          <Spacing size="lg" />
          <Flex gap="md">
            <AgencySelect
              prefix="Agency:"
              name="agency_id"
              control={control}
              withAll
              width="22rem"
              onValueChange={() => {
                setValue('advertiser_id', null);
              }}
            />
            <AdvertiserSelect
              agencyId={values.agency_id}
              prefix="Advertiser:"
              name="advertiser_id"
              control={control}
              withAll
              width="22rem"
            />
          </Flex>
        </>
      )}
      <Spacing size="lg" />
      <Tabs tabs={CREATIVES_TABS} value={tab} onChange={setTab} />
      <Spacing />
      <TableContainer>
        <Flex gap="lg" align="center">
          <ControlledInput width="40rem" name="search" control={control} prefix="Search:" placeholder="Keyword" />
          {!isViewOnly && (
            <Button various="secondary" size="sm" width="auto" onClick={onDownload}>
              DOWNLOAD
            </Button>
          )}
        </Flex>
        <Spacing />
        <DataTable {...dataTableProps} scroll />
      </TableContainer>
    </PageTemplate>
  );
};

const TableContainer = styled.div`
  background: white;
  padding: 2.4rem;
  box-shadow: ${TOKENS.shadow.default};
  border-radius: 1rem;
`;
