import { ApexOptions } from 'apexcharts';
import { format } from 'date-fns';
import { CHART_COLORS } from 'src/design';
import { growthApi } from 'src/services';
import { DateRange, fixPrecision, formatAmount, formatCount, formatPercentage } from 'src/utils';
import styled, { css } from 'styled-components';
import { OverviewFormValues } from './Overview';
import { Grid, Spacing, Text } from 'src/components/Layout';
import { useMemo, useState } from 'react';
import { Loading } from 'src/components/Loading';
import ReactApexChart from 'react-apexcharts';
import { DiffPercentage } from 'src/components/DiffPercentage';
import { nth } from 'lodash';
import { useRole, useWhiteLabelTheme } from 'src/hooks';

const { useCampaignPerformanceQuery } = growthApi;

type ChartSummaryProps = OverviewFormValues;

export const ChartSummary = (props: ChartSummaryProps) => {
  const { timeRange, dateFrom, dateTo, agencyId, advertiserId, campaignGroup, campaignId } = props;

  const { isAdmin } = useRole();

  const summaryItems = [
    { label: 'CTR', value: 'ctr', format: formatPercentage },
    { label: 'Impressions', value: 'impressions', format: formatCount },
    { label: 'Clicks', value: 'clicks', format: formatCount },
    { label: 'Conversions', value: 'total_conversions', format: formatCount },
    {
      label: 'CPC',
      value: 'cpc_markup',
      format: formatAmount,
    },
    {
      label: 'CPM',
      value: 'cpm_markup',
      format: formatAmount,
    },
    {
      label: 'CPA',
      value: 'cpa_markup',
      format: formatAmount,
    },
    {
      label: 'Spend',
      value: 'total_spend_markup',
      format: formatAmount,
    },
  ];

  const theme = useWhiteLabelTheme();
  const [currentSummaryItem, setCurrentSummaryItem] = useState<string>('ctr');

  // const { data: campaignData, isFetching: isCampaignFetching } = useCampaignPerformanceQuery({
  //   time_range: timeRange,
  //   date_from: dateFrom ? format(dateFrom, 'yyyy-MM-dd') : undefined,
  //   date_to: dateTo ? format(dateTo, 'yyyy-MM-dd') : undefined,
  //   agency_id: agencyId,
  //   advertiser_id: advertiserId,
  //   campaign_group: campaignGroup,
  //   campaign_id: campaignId,
  //   breakout: 'campaign',
  // });

  const dayBreakout = useMemo(() => {
    if (isAdmin) {
      if (!agencyId) {
        return 'agency_day';
      }
      if (!advertiserId) {
        return 'advertiser_day';
      }
    }
    return 'day';
  }, [advertiserId, agencyId, isAdmin]);

  const { data: chartData, isFetching: isChartFetching } = useCampaignPerformanceQuery({
    time_range: timeRange,
    date_from: dateFrom ? format(dateFrom, 'yyyy-MM-dd') : undefined,
    date_to: dateTo ? format(dateTo, 'yyyy-MM-dd') : undefined,
    agency_id: agencyId,
    advertiser_id: advertiserId,
    campaign_group: campaignGroup,
    campaign_id: campaignId,
    breakout: dayBreakout,
  });

  const isLoading = isChartFetching;

  const chartOptions: ApexOptions = {
    colors: CHART_COLORS(theme),
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      type: 'datetime',
      categories: chartData?.data?.map((row) => row.start_date) || [],
    },
    yaxis: {
      min: 0,
      forceNiceScale: true,
      decimalsInFloat: 2,
    },
    tooltip: {
      hideEmptySeries: false,
    },
    stroke: {
      curve: 'straight',
      width: 4,
    },
    fill: {
      type: 'solid',
      opacity: 0.35,
    },
  };
  const chartSeries: ApexAxisChartSeries = [
    {
      name: summaryItems.find((item) => item.value === currentSummaryItem)?.label,
      data:
        chartData?.data?.map((row) => (row[currentSummaryItem] !== '-' ? fixPrecision(row[currentSummaryItem]) : 0)) ||
        [],
    },
  ];

  const currentValue = timeRange === DateRange.Yesterday ? nth(chartData?.data, -1) : nth(chartData?.data, -2);
  const lastValue = nth(chartData?.data, -3);

  const summary = useMemo(() => {
    let totalImpressions = 0;
    let totalClicks = 0;
    let totalConversions = 0;
    let totalSpendMarkup = 0;

    chartData?.data?.forEach((item) => {
      totalImpressions += item['impressions'] !== '-' ? Number(item['impressions'] ?? 0) : 0;
      totalClicks += item['clicks'] !== '-' ? Number(item['clicks'] ?? 0) : 0;
      totalConversions += item['total_conversions'] !== '-' ? Number(item['total_conversions'] ?? 0) : 0;
      totalSpendMarkup += item['total_spend_markup'] !== '-' ? Number(item['total_spend_markup'] ?? 0) : 0;
    });

    return {
      totalImpressions,
      totalClicks,
      totalConversions,
      totalSpendMarkup,
    };
  }, [chartData?.data]);

  return (
    <Container>
      <Text size="md" weight={700}>
        Summary
      </Text>
      <Spacing size="lg" />
      {isLoading ? (
        <Loading height="35rem" />
      ) : (
        <>
          <Grid columns={8} gap="md">
            {summaryItems.map((item) => {
              let value = 0;
              switch (item.value) {
                case 'impressions':
                  value = summary.totalImpressions;
                  break;
                case 'clicks':
                  value = summary.totalClicks;
                  break;
                case 'total_conversions':
                  value = summary.totalConversions;
                  break;
                case 'total_spend_markup':
                  value = summary.totalSpendMarkup;
                  break;
                case 'ctr':
                  value = summary.totalImpressions === 0 ? 0 : (summary.totalClicks / summary.totalImpressions) * 100;
                  break;
                case 'cpc_markup':
                  value = summary.totalClicks === 0 ? 0 : summary.totalSpendMarkup / summary.totalClicks;
                  break;
                case 'cpm_markup':
                  value =
                    summary.totalImpressions === 0 ? 0 : (summary.totalSpendMarkup / summary.totalImpressions) * 1000;
                  break;
                case 'cpa_markup':
                  value = summary.totalConversions === 0 ? 0 : summary.totalSpendMarkup / summary.totalConversions;
                  break;
              }
              return (
                <SummaryItem
                  key={item.value}
                  active={currentSummaryItem === item.value}
                  onClick={() => setCurrentSummaryItem(item.value)}
                >
                  <Text size="xs">{item.label}</Text>
                  <Text size="lg" weight={700}>
                    {item.format ? item.format(value) : value}
                  </Text>
                  <DiffPercentage
                    color={currentSummaryItem === item.value ? 'white' : undefined}
                    value={currentValue?.[item.value]}
                    lastValue={lastValue?.[item.value]}
                  />
                </SummaryItem>
              );
            })}
          </Grid>
          <Spacing size="lg" />
          <ReactApexChart options={chartOptions} series={chartSeries} type="area" height={260} />
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  background: white;
  padding: 1.6rem 2.4rem;
  box-shadow: ${(props) => props.theme.shadow.default};
  border-radius: 1rem;
`;

const SummaryItem = styled.div<{ active: boolean }>`
  ${(props) =>
    props.active
      ? css`
          color: white;
          background: ${(props) => props.theme.color.primary};
        `
      : css`
          background: #fbfbfd;
        `};
  padding: 1.2rem 1.6rem;
  border-radius: 1rem;
  cursor: pointer;
`;
