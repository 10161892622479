export const META_VIDEO_CALL_TO_ACTION_OPTIONS = [
  'No button',
  'Subscribe',
  'Watch more',
  'Apply now',
  'Book now',
  'Contact us',
  'Download',
  'Get offer',
  'Get quote',
  'Get showtimes',
  'Learn more',
  'Listen now',
  'Order now',
  'Play game',
  'Request time',
  'See menu',
  'Shop now',
  'Sign up',
].map((item) => ({ label: item, value: item }));
