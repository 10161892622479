import { DataTable, DataTableExport } from 'src/components/DataTable';
import { Action, Button, ControlledInput, Switch, Tab, Tabs } from 'src/components/Form';
import { Flex, Spacing, Text } from 'src/components/Layout';
import { PageTemplate } from 'src/components/Template';
import { Link } from 'react-router-dom';
import { growthApi } from 'src/services';
import { formatAmount, formatOnlyDate } from 'src/utils';
import { useForm } from 'react-hook-form';
import { AgencySelect } from 'src/components/AgencySelect';
import { AdvertiserSelect } from 'src/components/AdvertiserSelect';
import { ArchiveNew } from 'src/components/Archive';
import { useCommonPersistentFilters, useDataTable, useRole } from 'src/hooks';
import { capitalize, pick } from 'lodash';
import { useMemo, useState } from 'react';
import { CampaignGroupModal } from './CampaignGroupModal';
import styled from 'styled-components';
import { TOKENS } from 'src/design';
import { Role } from 'src/types';
import { CampaignBulkUploadModal } from './CampaignBulkUploadModal';
import { CAMPAIGN_CHANNEL_OPTIONS_BY_VALUE } from './constants';

const { useCampaignsQuery, useArchiveCampaignMutation, useSetCampaignActiveMutation } = growthApi;

enum CampaignsTab {
  All = 'all',
  Live = 'live',
  Pending = 'Pending',
  Ended = 'ended',
  Draft = 'draft',
}

const CAMPAIGNS_TABS: Tab[] = [
  { label: 'All', value: CampaignsTab.All },
  { label: 'Live', value: CampaignsTab.Live },
  { label: 'Pending', value: CampaignsTab.Pending },
  { label: 'Ended', value: CampaignsTab.Ended },
  { label: 'Draft', value: CampaignsTab.Draft },
];

const OBJECTIVE_NAMES: Record<string, string> = {
  awareness: 'Awareness',
  click_and_traffic: 'Clicks & Traffic',
  video: 'Video',
  conversions: 'Conversions',
  audio: 'Audio',
};

type CampaignsFormValues = {
  agency_id?: number;
  advertiser_id?: number;
  search?: string;
};

export const Campaigns = () => {
  const { canAccessAgency, isViewOnly } = useRole();
  const { watch, control, setValue, reset } = useForm<CampaignsFormValues>();
  const values = watch();
  useCommonPersistentFilters({ values, setValues: reset });
  const [campaignGroupIsOpen, setCampaignGroupIsOpen] = useState<boolean>(false);
  const [archiveCampaign, { isLoading: isArchiving }] = useArchiveCampaignMutation();
  const [setCampaignActive] = useSetCampaignActiveMutation();
  const onArchive = async (row: any) => {
    await archiveCampaign({
      id: row.id,
    }).unwrap();
    refetch();
  };
  const [isBulkUploadOpen, setIsBulkUploadOpen] = useState(false);
  const [tab, setTab] = useState<CampaignsTab>(CampaignsTab.All);

  const { data, isFetching, error, refetch } = useCampaignsQuery({
    ...pick(values, ['agency_id', 'advertiser_id']),
  });

  const tabData = useMemo(() => {
    switch (tab) {
      case CampaignsTab.Live:
        return data?.data?.filter((row: any) => row.status === 'Live');
      case CampaignsTab.Pending:
        return data?.data?.filter((row: any) => row.status === 'Pending');
      case CampaignsTab.Ended:
        return data?.data?.filter((row: any) => row.status === 'Ended');
      case CampaignsTab.Draft:
        return data?.data?.filter((row: any) => row.status === 'Draft');
    }
    return data?.data;
  }, [data, tab]);

  const { dataTableProps, dataTableExportProps, selection, rowStatus, changeRowStatus } = useDataTable({
    name: 'campaign',
    idKey: 'id',
    data: tabData,
    search: values.search,
    isLoading: isFetching,
    enableSelection: !isViewOnly,
    error,
    searchKeys: ['id', 'campaign_name', 'advertiser_name', 'group'],
    defaultSort: {
      key: 'id',
      direction: 'desc',
    },
    sortNumberKeys: ['id', 'budget', 'is_active'],
    onChangeRowStatus: async (id: any, field: string, value: any) => {
      switch (field) {
        case 'is_active':
          await setCampaignActive({
            id,
            is_active: value,
          }).unwrap();
          break;
      }
    },
    columns: [
      {
        header: 'ID',
        accessor: 'id',
        sortable: true,
      },
      {
        header: 'Advertiser',
        accessor: 'advertiser_name',
        width: '10rem',
        sortable: true,
        when: () => canAccessAgency,
      },
      {
        header: 'Channel',
        accessor: 'channel',
        width: '10rem',
        sortable: true,
        render: (value) => {
          return CAMPAIGN_CHANNEL_OPTIONS_BY_VALUE[value]?.label || '-';
        },
      },
      { header: 'Campaign Name', accessor: 'campaign_name', width: '16rem', sortable: true },
      { header: 'Group', accessor: 'group', width: '10rem', sortable: true, render: (value) => value ?? '-' },
      { header: 'Objective', accessor: 'objective', render: (value) => OBJECTIVE_NAMES[value] ?? '-', sortable: true },
      {
        header: 'Budget',
        accessor: 'budget_amount',
        render: (value, row) => {
          if (row.ongoing_enabled) {
            return `${formatAmount(value)} (${capitalize(row.ongoing_interval)})`;
          } else {
            return formatAmount(value);
          }
        },
        sortable: true,
      },
      { header: 'Start Date', accessor: 'schedule_start_date', sortable: true, render: formatOnlyDate },
      {
        header: 'End Date',
        accessor: 'schedule_end_date',
        sortable: true,
        render: formatOnlyDate,
      },
      {
        header: 'Status',
        accessor: 'status',
        sortable: true,
      },
      {
        header: 'On/Off',
        accessor: 'is_active',
        when: (user) => user?.role !== Role.ViewOnly,
        render: (value, row) => {
          if (row.is_draft) {
            return '-';
          }
          return (
            <Switch
              value={rowStatus[row.id]?.is_active ?? Boolean(Number(value))}
              onChange={(status) => changeRowStatus(row.id, 'is_active', status, row)}
            />
          );
        },
        export: (value, row) => {
          if (row.is_draft) {
            return '-';
          }
          return value ? 'on' : 'off';
        },
        sortable: true,
      },
      {
        header: '',
        accessor: 'action',
        width: '3rem',
        when: (user) => user?.role !== Role.ViewOnly,
        render: (_, row) => (
          <Flex gap="md">
            <Link to={`/activate/campaigns/edit?copy=${row.id}`}>
              <Action>Copy</Action>
            </Link>
            <Link to={`/activate/campaigns/edit?id=${row.id}`}>
              <Action>Edit</Action>
            </Link>
            <ArchiveNew onArchive={() => onArchive(row)} isArchiving={isArchiving} />
          </Flex>
        ),
        export: false,
      },
    ],
  });

  return (
    <PageTemplate>
      <Flex justify="space-between" align="center">
        <Flex align="center" gap="lg">
          <Text size="xxl" weight={700}>
            Campaigns
          </Text>
        </Flex>
        {!isViewOnly && (
          <Flex gap="lg">
            <Button various="secondary" width="16rem" onClick={() => setIsBulkUploadOpen(true)}>
              BULK UPLOAD
            </Button>
            <Link to="/activate/campaigns/new">
              <Button width="16rem" shadow>
                NEW CAMPAIGN
              </Button>
            </Link>
          </Flex>
        )}
      </Flex>
      {canAccessAgency && (
        <>
          <Spacing size="lg" />
          <Flex gap="md">
            <AgencySelect
              prefix="Agency:"
              name="agency_id"
              control={control}
              withAll
              width="22rem"
              onValueChange={() => {
                setValue('advertiser_id', null);
              }}
            />
            <AdvertiserSelect
              agencyId={values.agency_id}
              prefix="Advertiser:"
              name="advertiser_id"
              control={control}
              withAll
              width="22rem"
            />
          </Flex>
        </>
      )}
      <Spacing size="lg" />
      <Tabs tabs={CAMPAIGNS_TABS} value={tab} onChange={setTab} />
      <Spacing />
      <TableContainer>
        <Flex justify="space-between" align="center">
          <Flex gap="lg" align="center">
            <ControlledInput width="40rem" name="search" control={control} prefix="Search:" placeholder="Keyword" />
            {!isViewOnly && (
              <Button various="secondary" size="sm" width="auto" onClick={() => setCampaignGroupIsOpen(true)}>
                GROUP
              </Button>
            )}
          </Flex>
          <DataTableExport {...dataTableExportProps} />
        </Flex>
        <Spacing />
        <DataTable {...dataTableProps} scroll />
      </TableContainer>
      <CampaignBulkUploadModal isOpen={isBulkUploadOpen} onClose={() => setIsBulkUploadOpen(false)} />
      <CampaignGroupModal
        campaignIds={selection}
        isOpen={campaignGroupIsOpen}
        onClose={() => setCampaignGroupIsOpen(false)}
        onSuccess={() => refetch()}
      />
    </PageTemplate>
  );
};

const TableContainer = styled.div`
  background: white;
  padding: 2.4rem;
  box-shadow: ${TOKENS.shadow.default};
  border-radius: 1rem;
`;
