import { Icon } from 'src/components/Icon';
import { useController, UseControllerProps } from 'react-hook-form';
import styled, { css } from 'styled-components';

export type ControlledRadioGroupOption = {
  label: string;
  value: string | number;
};

const CONTROLLED_RADIO_GROUP_SIZE_CSS = {
  sm: css`
    font-size: 1.2rem;
  `,
  md: css`
    font-size: 1.4rem;
  `,
  lg: css`
    font-size: 1.6rem;
  `,
};

type ControlledRadioGroupProps<T> = UseControllerProps<T> & {
  options?: ControlledRadioGroupOption[];
  width?: string;
  size?: keyof typeof CONTROLLED_RADIO_GROUP_SIZE_CSS;
  vertical?: boolean;
  disabled?: boolean;
  onValueChange?: (value: any) => void;
};

export const ControlledRadioGroup = <T,>(props: ControlledRadioGroupProps<T>) => {
  const { options, width, size, vertical, disabled, onValueChange, ...useControllerProps } = props;
  const {
    field: { onChange, value },
  } = useController(useControllerProps);

  const selectOption = (option: ControlledRadioGroupOption) => {
    if (disabled) {
      return;
    }
    onChange(option.value);
    onValueChange?.(option.value);
  };

  return (
    <Container width={width} size={size} vertical={vertical}>
      {options?.map((option) => (
        <Option
          key={option.value}
          active={value === option.value}
          disabled={disabled}
          onClick={() => selectOption(option)}
        >
          <Icon type={value === option.value ? 'radioOn' : 'radioOff'} size={size || 'md'} />
          <div>{option.label}</div>
        </Option>
      ))}
    </Container>
  );
};

const Container = styled.div<Pick<ControlledRadioGroupProps<any>, 'width' | 'size' | 'vertical'>>`
  display: flex;
  gap: 1.6rem;
  padding: 0.6rem 0;
  width: ${(props) => props.width ?? '100%'};
  ${(props) => CONTROLLED_RADIO_GROUP_SIZE_CSS[props.size ?? 'md']}
  ${(props) =>
    props.vertical
      ? css`
          flex-direction: column;
        `
      : ''}
`;

const Option = styled.div<{ active?: boolean; disabled?: boolean }>`
  display: flex;
  align-items: center;
  gap: 0.6rem;

  ${(props) =>
    !props.disabled
      ? css`
          cursor: pointer;
        `
      : ''}

  svg {
    ${(props) =>
      props.active
        ? css`
            fill: ${props.theme.color.primary};
          `
        : css`
            fill: ${props.theme.color.gray};
          `}
  }
`;
