// ABM audience
export const ABM_ANNUAL_REVENUE_OPTIONS = [
  'Micro (Under $1mm)',
  'Small ($1mm To $10mm)',
  'Medium ($10mm To $50mm)',
  'Medium Large ($50mm To $100mm)',
  'Large ($100mm To $1bn)',
  'Extra Large (Over $1bn)',
].map((item) => ({ label: item, value: item }));

export const ABM_NUMBER_OF_EMPLOYEES_OPTIONS = [
  'Micro (1-20)',
  'Small (20-100)',
  'Medium (100-500)',
  'Medium Large (500-1,000)',
  'Large (1,000-5,000)',
  'Extra Large (Over 5,000)',
].map((item) => ({ label: item, value: item }));

export const ABM_SENIORITY_OPTIONS = [
  'Founder, Owner, or Partner',
  'Advisors and Board of Directors',
  'C-Level',
  'Vice President',
  'Director',
  'Manager',
  'Staff and Non-Management',
  'Student',
].map((item) => ({ label: item, value: item }));

export const ABM_INDUSTRY_OPTIONS = [
  'Agriculture',
  'Apparel',
  'Automotive',
  'Business Services',
  'Business Services Advertisment and Marketing',
  'Business Services (General)',
  'Construction',
  'Consumer Services',
  'Education',
  'Education Colleges and Universities',
  'Education (General)',
  'Energy and Utilities',
  'Finance',
  'Finance Investment Banking',
  'Finance (General)',
  'Food and Beverages',
  'Government',
  'Healthcare',
  'Healthcare Hospitals and Clinics',
  'Healthcare (General)',
  'Healthcare Insurance',
  'Hospitality and Hotels',
  'Information Technology',
  'Insurance',
  'Legal',
  'Manufacturing',
  'Media',
  'Media Internet',
  'Media (General)',
  'Mining',
  'Non-Profit',
  'Oil and Gas',
  'Pharmaceutical',
  'Real Estate',
  'Real Estate Residential',
  'Real Estate (General)',
  'Religious Services',
  'Retail',
  'Software',
  'Software Security',
  'Software (General)',
  'Sports and Recreation',
  'Telecommunications',
  'Transportation',
  'Travel',
  'Waste Services',
  'Wholesalers',
].map((item) => ({ label: item, value: item }));

export const LOCATION_OPTIONS = [
  'Afghanistan',
  'Albania',
  'Algeria',
  'American Samoa',
  'Andorra',
  'Angola',
  'Anguilla',
  'Antigua and Barbuda',
  'Argentina',
  'Armenia',
  'Aruba',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bermuda',
  'Bhutan',
  'Bolivia',
  'Bonaire, Sint Eustatius, and Saba',
  'Bosnia and Herzegovina',
  'Botswana',
  'Bouvet Island',
  'Brazil',
  'British Indian Ocean Territory',
  'British Virgin Islands',
  'Brunei',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  'Cabo Verde',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Cayman Islands',
  'Central African Republic',
  'Chad',
  'Chile',
  'China',
  'Christmas Island',
  'Cocos [Keeling] Islands',
  'Colombia',
  'Comoros',
  'Congo Republic',
  'Cook Islands',
  'Costa Rica',
  'Croatia',
  'Cuba',
  'Curaçao',
  'Cyprus',
  'Czechia',
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic',
  'DR Congo',
  'East Timor',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Eritrea',
  'Estonia',
  'Eswatini',
  'Ethiopia',
  'Falkland Islands',
  'Faroe Islands',
  'Federated States of Micronesia',
  'Fiji',
  'Finland',
  'France',
  'French Guiana',
  'French Polynesia',
  'French Southern Territories',
  'Gabon',
  'Gambia',
  'Georgia',
  'Germany',
  'Ghana',
  'Gibraltar',
  'Greece',
  'Greenland',
  'Grenada',
  'Guadeloupe',
  'Guam',
  'Guatemala',
  'Guernsey',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Hashemite Kingdom of Jordan',
  'Heard Island and McDonald Islands',
  'Honduras',
  'Hong Kong',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran',
  'Iraq',
  'Ireland',
  'Isle of Man',
  'Israel',
  'Italy',
  'Ivory Coast',
  'Jamaica',
  'Japan',
  'Jersey',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  'Kosovo',
  'Kuwait',
  'Kyrgyzstan',
  'Laos',
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Luxembourg',
  'Macao',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Marshall Islands',
  'Martinique',
  'Mauritania',
  'Mauritius',
  'Mayotte',
  'Mexico',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Montserrat',
  'Morocco',
  'Mozambique',
  'Myanmar',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands',
  'New Caledonia',
  'New Zealand',
  'Nicaragua',
  'Niger',
  'Nigeria',
  'Niue',
  'Norfolk Island',
  'North Macedonia',
  'Northern Mariana Islands',
  'Norway',
  'Oman',
  'Pakistan',
  'Palau',
  'Palestine',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines',
  'Pitcairn Islands',
  'Poland',
  'Portugal',
  'Puerto Rico',
  'Qatar',
  'Republic of Lithuania',
  'Republic of Moldova',
  'Réunion',
  'Romania',
  'Russia',
  'Rwanda',
  'Saint Barthélemy',
  'Saint Helena',
  'Saint Lucia',
  'Saint Martin',
  'Saint Pierre and Miquelon',
  'Saint Vincent and the Grenadines',
  'Samoa',
  'San Marino',
  'São Tomé and Príncipe',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Sint Maarten',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  'South Georgia and the South Sandwich Islands',
  'South Korea',
  'South Sudan',
  'Spain',
  'Sri Lanka',
  'St Kitts and Nevis',
  'Sudan',
  'Suriname',
  'Svalbard and Jan Mayen',
  'Sweden',
  'Switzerland',
  'Syria',
  'Taiwan',
  'Tajikistan',
  'Tanzania',
  'Thailand',
  'Togo',
  'Tokelau',
  'Tonga',
  'Trinidad and Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Turks and Caicos Islands',
  'Tuvalu',
  'U.S. Minor Outlying Islands',
  'U.S. Virgin Islands',
  'Uganda',
  'Ukraine',
  'United Arab Emirates',
  'United Kingdom',
  'United States',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Vatican City',
  'Venezuela',
  'Vietnam',
  'Wallis and Futuna',
  'Western Sahara',
  'Yemen',
  'Zambia',
  'Zimbabwe',
].map((item) => ({ label: item, value: item }));

export const ABM_TITLES_OPTIONS = [
  'Administrator',
  'Administrator Admin',
  'Administrator Assistant',
  'Administrator Office Manager',
  'Administrator Secretary',
  'Administrator Typist',
  'Administrator Receptionist',
  'Consultants',
  'Education',
  'Education Admissions',
  'Education (General)',
  'Education Librarian',
  'Education Professor',
  'Education Teacher',
  'Engineering',
  'Engineering Biomedical',
  'Engineering Chemical',
  'Engineering Civil',
  'Engineering Electrical',
  'Engineering Electronics',
  'Engineering (General)',
  'Engineering Industrial',
  'Engineering Mechanical',
  'Finance',
  'Finance Accounting',
  'Finance Analyst',
  'Finance Audit',
  'Finance Banking',
  'Finance Certified Financial Planner (CFP)',
  'Finance Compliance Specialist',
  'Finance Corporate',
  'Finance Chartered Professional Accountant (CPA)',
  'Finance Economist',
  'Finance (General)',
  'Finance Investment Banking',
  'Finance Mortgage Specialist',
  'Finance Risk Management',
  'Finance Statistician',
  'Finance Tax Specialist',
  'Finance Treasurer',
  'Finance Wealth Management',
  'Government',
  'Government Elected Officials',
  'Government (General)',
  'Healthcare',
  'Healthcare Chiropractor',
  'Healthcare Dental Assistant',
  'Healthcare Dental Hygenist',
  'Healthcare Dentist',
  'Healthcare Dietician',
  'Healthcare (General)',
  'Healthcare Professional',
  'Healthcare Nurse',
  'Healthcare Opticians',
  'Healthcare Optometrist',
  'Healthcare Paramedic',
  'Healthcare Pharmaceuticals',
  'Healthcare Pharmacist',
  'Healthcare Physical Therapist',
  'Healthcare Physician (Doctor)',
  'Healthcare Physicians Assistant',
  'Healthcare Psychologist',
  'Healthcare Therapist',
  'Human Resources',
  'Human Resources Benefits and Compensation',
  'Human Resources Employee Development',
  'Human Resources (General)',
  'Human Resources Payroll Specialist',
  'Human Resources Recruiting',
  'Law Enforcement',
  'Law Enforcement Correctional Workers',
  'Law Enforcement (General)',
  'Law Enforcement Parole Officer',
  'Law Enforcement Police Officer',
  'Legal',
  'Legal (General)',
  'Legal Judge',
  'Legal Lawyer',
  'Legal Paralegal',
  'Legal Prosecutor',
  'Marketing',
  'Marketing Advertising',
  'Marketing Creative',
  'Marketing Events',
  'Marketing (General)',
  'Marketing Communications',
  'Military',
  'Military Air Force',
  'Military Army',
  'Military Coast Guard',
  'Military Marines',
  'Military (General)',
  'Military Navy',
  'Operations',
  'Operations Environmental',
  'Operations Facility Maintenance',
  'Operations Food and Beverage',
  'Operations (General)',
  'Operations Manufacturing',
  'Operations Quality Assurance',
  'Operations Records Management',
  'Operations Safety',
  'Operations Training',
  'Photographer',
  'Real Estate',
  'Real Estate Architect',
  'Real Estate Builder',
  'Real Estate Contractor',
  'Real Estate Developer',
  'Real Estate (General)',
  'Real Estate Mortgage Specialist',
  'Real Estate Agent',
  'Religious Leader',
  'Research and Development',
  'Research and Development Product Development',
  'Research and Development Project Management',
  'Research and Development Research',
  'Sales',
  'Sales Account Management',
  'Sales Business Development',
  'Sales Community Development',
  'Sales Customer Service',
  'Sales (General)',
  'Science',
  'Science Chemist',
  'Science Geologist',
  'Science Scientist',
  'Stay at Home Parent',
  'Supply Chain',
  'Supply Chain Logistics',
  'Supply Chain Procurement',
  'Supply Chain Sourcing',
  'Technology',
  'Technology Computing',
  'Technology Data Management',
  'Technology Database Administrator',
  'Technology Information Security',
  'Technology Information Technology',
  'Technology Management Information Systems',
  'Technology Network Administration',
  'Technology Quality Assurance',
  'Technology Software',
  'Technology Systems Administration',
  'Technology Technical Support',
  'Technology Telecommunications',
  'Technology Website Development',
  'Writer',
].map((item) => ({ label: item, value: item }));

export const AGE_OPTIONS = ['18-24', '25-29', '30-34', '35-39', '40-49', '50-59', '60-64', '65+'].map((item) => ({
  label: item,
  value: item,
}));

export const GENDER_OPTIONS = ['Any', 'Female', 'Male'].map((item) => ({ label: item, value: item }));

export const LANGUAGE_OPTIONS = [
  'Abkhaz',
  'Afar',
  'Afrikaans',
  'Akan',
  'Albanian',
  'Amharic',
  'Arabic',
  'Aragonese',
  'Armenian',
  'Assamese',
  'Avaric',
  'Avestan',
  'Aymara',
  'Azerbaijani',
  'Bambara',
  'Bashkir',
  'Basque',
  'Belarusian',
  'Bengali (Bangla)',
  'Bihari',
  'Bislama',
  'Bosnian',
  'Breton',
  'Bulgarian',
  'Burmese',
  'Catalan/Valencian',
  'Chamorro',
  'Chechen',
  'Chichewa (Nyanja)',
  'Chinese',
  'Church Slavonic/Old Bulgarian/Old Church Slavonic',
  'Chuvash',
  'Cornish',
  'Corsican',
  'Cree',
  'Croatian',
  'Czech',
  'Danish',
  'Divehi (Maldivian)',
  'Dutch',
  'Dzongkha',
  'English',
  'Esperanto',
  'Estonian',
  'Ewe',
  'Faroese',
  'Fijian',
  'Finnish',
  'French',
  'Fula (Pulaar)',
  'Galician',
  'Ganda',
  'Georgian',
  'German',
  'Greek',
  'Guaraní',
  'Gujarati',
  'Haitian (Haitian Creole)',
  'Hausa',
  'Hebrew',
  'Herero',
  'Hindi',
  'Hiri Motu',
  'Hungarian',
  'Icelandic',
  'Ido',
  'Igbo',
  'Indonesian',
  'Interlingua',
  'Interlingue',
  'Inuktiut',
  'Inupiaq',
  'Irish',
  'Italian',
  'Japanese',
  'Javanese',
  'Kalaallisut (Greenlandic)',
  'Kannada',
  'Kanuri',
  'Kashmiri',
  'Kazakh',
  'Khmer',
  'Kikuyu (Gikuyu)',
  'Kinyarwanda',
  'Kirundi',
  'Komi',
  'Kongo',
  'Korean',
  'Kurdish',
  'Kwanyama',
  'Kyrgyz',
  'Lao',
  'Latin',
  'Latvian',
  'Limburgish',
  'Lingala',
  'Lithuanian',
  'Luba-Katanga',
  'Luxembourgish',
  'Macedonian',
  'Malagasy',
  'Malay',
  'Malayalam',
  'Maltese',
  'Manx',
  'Maori',
  'Marathi',
  'Marshallese',
  'Mongolian',
  'Nauru',
  'Navajo',
  'Ndonga',
  'Nepali',
  'North Ndebele',
  'Northern Sami',
  'Norwegian',
  'Norwegian Bokmal',
  'Norwegian Nynorsk',
  'Nuosu',
  'Occitan',
  'Ojibwe',
  'Oriya',
  'Oromo',
  'Ossetian',
  'Panjabi (Punjabi)',
  'Pashto (Pushto)',
  'Persian (Farsi)',
  'Polish',
  'Portuguese',
  'Quechua',
  'Romanian',
  'Romansh',
  'Russian',
  'Samoan',
  'Sango',
  'Sanskrit',
  'Sardinian',
  'Scottish Gaelic',
  'Serbian',
  'Shona',
  'Sindhi',
  'Sinhala',
  'Slovak',
  'Slovene',
  'Somali',
  'South Ndebele',
  'Southern Sotho',
  'Spanish',
  'Standard Tibetan',
  'Sundanese',
  'Swahili',
  'Swati',
  'Swedish',
  'Tagalog',
  'Tahitian',
  'Tajik',
  'Tamil',
  'Tatar',
  'Telugu',
  'Thai',
  'Tigrinya',
  'Tonga',
  'Tsonga',
  'Tswana',
  'Turkish',
  'Turkmen',
  'Twi',
  'Ukrainian',
  'Urdu',
  'Uyghur (Uighur)',
  'Uzbek',
  'Venda',
  'Vietnamese',
  'Volapük',
  'Walloon',
  'Welsh',
  'Western Frisian',
  'Wolof',
  'Xhosa',
  'Yiddish',
  'Yoruba',
  'Zhuang (Chuang)',
].map((item) => ({ label: item, value: item }));

export const META_INTERESTS_OPTIONS = [
  'Advertising',
  'Agriculture',
  'Architecture',
  'Aviation',
  'Banking',
  'Investment banking',
  'Online banking',
  'Retail banking',
  'Investment banking',
  'Online banking',
  'Retail banking',
  'Business',
  'Construction',
  'Design',
  'Fashion design',
  'Graphic design',
  'Interior design',
  'Economics',
  'Engineering',
  'Entrepreneurship',
  'Health care',
  'Higher education',
  'Management',
  'Marketing',
  'Nursing',
  'Online',
  'Digital marketing',
  'Display advertising',
  'Email marketing',
  'Online advertising',
  'Search engine optimization',
  'Social media',
  'Social media marketing',
  'Web design',
  'Web development',
  'Web hosting',
  'Personal finance',
  'Creditcards',
  'Insurance',
  'Investment',
  'Mortgage loans',
  'Real estate',
  'Retail',
  'Sales',
  'Science',
  'Small business',
  'Action games',
  'Board games',
  'Browser games',
  'Card games',
  'Casino games',
  'First-person shooter games',
  'Gambling',
  'Massively multiplayer online games',
  'Massively multiplayer online role-playing games',
  'Online games',
  'Online poker',
  'Puzzle video games',
  'Racing games',
  'Role-playing games',
  'Shooter games',
  'Simulation games',
  'Sports games',
  'Strategy games',
  'Video games',
  'Word games',
  'Ballet',
  'Bars',
  'Concerts',
  'Dancehalls',
  'Music festivals',
  'Nightclubs',
  'Parties',
  'Plays',
  'Theatre',
  'Action movies',
  'Animated movies',
  'Anime movies',
  'Bollywood movies',
  'Comedy movies',
  'Documentary movies',
  'Drama movies',
  'Fantasy movies',
  'Horror movies',
  'Musical theatre',
  'Science fiction movies',
  'Thriller movies',
  'Blues music',
  'Classical music',
  'Country music',
  'Dance music',
  'Electronic music',
  'Gospel music',
  'Heavy metal music',
  'Hip hop music',
  'Jazz music',
  'Music videos',
  'Pop music',
  'Rhythm and blues music',
  'Rock musicSoul music',
  'Books',
  'Comics',
  'E-books',
  'Fiction books',
  'Literature',
  'Magazines',
  'Manga',
  'Mystery fiction',
  'Newspapers',
  'Non-fiction books',
  'Romance novels',
  'TV comedies',
  'TV game shows',
  'TV reality shows',
  'TV talkshows',
  'Dating',
  'Family',
  'Fatherhood',
  'Friendship',
  'Marriage',
  'Motherhood',
  'Parenting',
  'Weddings',
  'Bodybuilding',
  'Meditation',
  'Physical exercise',
  'Physical fitness',
  'Running',
  'Weight training',
  'Yoga',
  'Beer',
  'Distilled beverage',
  'Wine',
  'Coffee',
  'Energy drinks',
  'Juice',
  'Soft drinks',
  'Tea',
  'Baking',
  'Recipes',
  'Chinese cuisine',
  'French cuisine',
  'German cuisine',
  'Greek cuisine',
  'Indian cuisine',
  'Italian cuisine',
  'Japanese cuisine',
  'Korean cuisine',
  'Latin American cuisine',
  'Mexican cuisine',
  'Middle Eastern cuisine',
  'Spanish cuisine',
  'Thai cuisine',
  'Vietnamese cuisine',
  'Barbecue',
  'Chocolate',
  'Desserts',
  'Fast food',
  'Organic food',
  'Pizza',
  'Seafood',
  'Veganism',
  'Vegetarianism',
  'Coffeehouses',
  'Diners',
  'Fast casual restaurants',
  'Fast food restaurants',
  'Acting',
  'Crafts',
  'Dance',
  'Drawing',
  'Drums',
  'Fine art',
  'Guitar',
  'Painting',
  'Performing arts',
  'Photography',
  'Sculpture',
  'Singing',
  'Writing',
  'Do it yourself (DIY)',
  'Furniture',
  'Gardening',
  'Home Appliances',
  'Home improvement',
  'Birds',
  'Cats',
  'Dogs',
  'Fish',
  'Horses',
  'Pet food',
  'Rabbits',
  'Reptiles',
  'Charity and causes',
  'Community issues',
  'Environmentalism',
  'Law',
  'Military',
  'Politics',
  'Religion',
  'Sustainability',
  'Veterans',
  'Volunteering',
  'Adventure travel',
  'Air travel',
  'Beaches',
  'Car rentals',
  'Cruises',
  'Ecotourism',
  'Hotels',
  'Lakes',
  'Mountains',
  'Nature',
  'Theme parks',
  'Tourism',
  'Vacations',
  'Automobiles',
  'Boats',
  'Electric vehicle',
  'Hybrids',
  'Minivans',
  'Motorcycles',
  'RVs',
  'SUVs',
  'Scooters',
  'Trucks',
  'Beauty salons',
  'Cosmetics',
  'Fragrances',
  'Hair products',
  'Spas',
  'Tattoos',
  'Children’s clothing',
  'Men’s clothing',
  'Shoes',
  'Women’s clothing',
  'Dresses',
  'Handbags',
  'Jewelry',
  'Sunglasses',
  'Boutiques',
  'Coupons',
  'Discount stores',
  'Luxury goods',
  'Online shopping',
  'Shopping malls',
  'Boating',
  'Camping',
  'Fishing',
  'Horseback riding',
  'Hunting',
  'Mountain biking',
  'Surfing',
  'American football',
  'Association football (Soccer)',
  'Auto racing',
  'Baseball',
  'Basketball',
  'College football',
  'Golf',
  'Marathons',
  'Skiing',
  'Snowboarding',
  'Swimming',
  'Tennis',
  'Thriathlons',
  'Volleyball',
  'Computer memory',
  'Computer monitors',
  'Computer processors',
  'Computer servers',
  'Desktop computers',
  'Free software',
  'Hard drives',
  'Network storage',
  'Software',
  'Tablet computers',
  'Audio equipment',
  'Camcorders',
  'Cameras',
  'E-book readers',
  'GPS devices',
  'Game consoles',
  'Mobile phones',
  'Portable media players',
  'Projectors',
  'Smartphones',
  'Televisions',
].map((item) => ({ label: item, value: item }));
