import {
  Button,
  ControlledFile,
  ControlledInput,
  ControlledRadioGroup,
  ControlledRadioGroupOption,
  ControlledSelect,
  ControlledSwitch,
  ControlledTextArea,
  Form,
  FormAction,
  FormItem,
  Required,
  Tabs,
} from 'src/components/Form';
import { Flex, FlexItem, Grid, Spacing, Text } from 'src/components/Layout';
import { Icon, IconProps } from 'src/components/Icon';
import { TOKENS } from 'src/design';
import styled, { css } from 'styled-components';
import { nanoid } from 'nanoid';
import { useFormContext } from 'react-hook-form';
import { ControlledDatePicker } from 'src/components/Form/ControlledDatePicker';
import { cloneDeep } from 'lodash';
import { PixelSelect } from 'src/components/PixelSelect';
import {
  CampaignObjective,
  CampaignBillingType,
  CampaignDeliveryFrequencyInterval,
  CampaignEngagement,
  CampaignOngoingInterval,
  CampaignPacing,
  CampaignChannel,
  CampaignConversionLocationType,
} from 'src/types';
import { CampaignEditFormValues } from './CampaignEdit';
import { useToast } from 'src/hooks';
import {
  CAMPAIGN_CONVERSION_LOCATION_TYPE_OPTIONS,
  CAMPAIGN_DEVICE_OPTIONS,
  CAMPAIGN_META_CUSTOMIZE_PLACEMENTS_OPTIONS,
  CAMPAIGN_META_INSTANT_FORM_FIELD_OPTIONS,
} from './constants';
import { useState } from 'react';

const CAMPAIGN_BILLING_TYPE_OPTIONS = [
  {
    label: 'CTR',
    value: CampaignBillingType.CTR,
  },
  {
    label: 'Viewability Rate',
    value: CampaignBillingType.ViewabilityRate,
  },
  {
    label: 'CPC',
    value: CampaignBillingType.CPC,
  },
  {
    label: 'CPV',
    value: CampaignBillingType.CPV,
  },
  {
    label: 'CPCV',
    value: CampaignBillingType.CPCV,
  },
  {
    label: 'VCR',
    value: CampaignBillingType.VCR,
  },
  {
    label: 'CPA',
    value: CampaignBillingType.CPA,
  },
  {
    label: 'ROI',
    value: CampaignBillingType.ROI,
  },
  {
    label: 'CPL',
    value: CampaignBillingType.CPL,
  },
  {
    label: 'CPCL',
    value: CampaignBillingType.CPCL,
  },
  {
    label: 'Maximize number of leads',
    value: CampaignBillingType.MaxLeads,
  },
  {
    label: 'Maximize number of conversion leads',
    value: CampaignBillingType.MaxConversionLeads,
  },
];

const CAMPAIGN_ONGOING_INTERVAL_OPTIONS = [
  {
    label: 'Daily',
    value: CampaignOngoingInterval.Daily,
  },
  {
    label: 'Weekly',
    value: CampaignOngoingInterval.Weekly,
  },
  {
    label: 'Monthly',
    value: CampaignOngoingInterval.Monthly,
  },
  {
    label: 'Flights',
    value: CampaignOngoingInterval.Flights,
  },
];

const CAMPAIGN_PACING_OPTIONS: ControlledRadioGroupOption[] = [
  {
    label: 'Spend Evenly Through End Date',
    value: CampaignPacing.SpendEvenly,
  },
  {
    label: 'Spend ASAP',
    value: CampaignPacing.SpendASAP,
  },
];

const CAMPAIGN_ENGAGEMENT_OPTIONS: ControlledRadioGroupOption[] = [
  {
    label: 'No engagement tracking',
    value: CampaignEngagement.NoEngagement,
  },
  {
    label: 'Use Pixel',
    value: CampaignEngagement.Pixel,
  },
];

const CAMPAIGN_FREQUENCY_INTERVAL_OPTIONS: ControlledRadioGroupOption[] = [
  {
    label: 'Hour',
    value: CampaignDeliveryFrequencyInterval.Hour,
  },
  {
    label: 'Day',
    value: CampaignDeliveryFrequencyInterval.Day,
  },
  {
    label: 'Week',
    value: CampaignDeliveryFrequencyInterval.Week,
  },
  {
    label: 'Month',
    value: CampaignDeliveryFrequencyInterval.Month,
  },
];

const CAMPAIGN_OBJECTIVE_BILLING_TYPES: Record<CampaignObjective, CampaignBillingType[]> = {
  [CampaignObjective.Awareness]: [CampaignBillingType.CTR, CampaignBillingType.ViewabilityRate],
  [CampaignObjective.ClickAndTraffic]: [CampaignBillingType.CPC, CampaignBillingType.CTR],
  [CampaignObjective.Video]: [
    CampaignBillingType.CPV,
    CampaignBillingType.CPCV,
    CampaignBillingType.VCR,
    CampaignBillingType.CPC,
    CampaignBillingType.CTR,
    CampaignBillingType.ViewabilityRate,
  ],
  [CampaignObjective.Conversions]: [CampaignBillingType.CPA, CampaignBillingType.ROI],
  [CampaignObjective.Audio]: [CampaignBillingType.CPL, CampaignBillingType.CPCL],
  [CampaignObjective.Leads]: [CampaignBillingType.MaxLeads, CampaignBillingType.MaxConversionLeads],
};

type SetCampaignObjectiveProps = {
  enableDraft: boolean;
  onDraftSubmit: () => void;
  onBack: () => void;
  onNext: () => void;
};

export const SetCampaignObjective = (props: SetCampaignObjectiveProps) => {
  const { enableDraft, onDraftSubmit, onBack, onNext } = props;

  const { watch, control, setValue, formState } = useFormContext<CampaignEditFormValues>();
  const values = watch();
  const { showErrorToast } = useToast();
  const [instantFormTab, setInstantFormTab] = useState('intro');

  const getCampaignObjectives = (): { title: string; value: CampaignObjective; icon: IconProps['type'] }[] => {
    if (values.channel === CampaignChannel.Meta) {
      return [
        {
          title: 'Awareness',
          value: CampaignObjective.Awareness,
          icon: 'users',
        },
        {
          title: 'Clicks & Traffic',
          value: CampaignObjective.ClickAndTraffic,
          icon: 'pixels',
        },
        {
          title: 'Leads',
          value: CampaignObjective.Leads,
          icon: 'reporting',
        },
      ];
    }
    return [
      {
        title: 'Awareness',
        value: CampaignObjective.Awareness,
        icon: 'users',
      },
      {
        title: 'Clicks & Traffic',
        value: CampaignObjective.ClickAndTraffic,
        icon: 'pixels',
      },
      {
        title: 'Video',
        value: CampaignObjective.Video,
        icon: 'video',
      },
      {
        title: 'Audio',
        value: CampaignObjective.Audio,
        icon: 'voice',
      },
      {
        title: 'Conversions',
        value: CampaignObjective.Conversions,
        icon: 'bag',
      },
    ];
  };

  const onAddOngoingFlight = (index: number) => {
    const flights = cloneDeep(values.ongoing_flights);
    flights.splice(index + 1, 0, { id: nanoid() });
    setValue('ongoing_flights', flights);
  };

  const onRemoveOngoingFlight = (index: number) => {
    setValue(
      'ongoing_flights',
      values.ongoing_flights.filter((_, flightIndex) => flightIndex !== index),
    );
  };

  const validate = () => {
    const errors = [];
    if (values.channel === CampaignChannel.Meta) {
      if (!values.meta?.business_id) {
        errors.push('Connect Meta Business ID - business ID or page URL is required');
      }
    }
    if (values.ongoing_enabled) {
      if (!values.ongoing_interval) {
        errors.push('Schedule - ongoing interval is required');
      }
      if (
        [CampaignOngoingInterval.Daily, CampaignOngoingInterval.Weekly, CampaignOngoingInterval.Monthly].includes(
          values.ongoing_interval,
        ) &&
        !values.ongoing_interval_budget
      ) {
        errors.push('Schedule - ongoing interval budget is required');
      }
      if (values.ongoing_interval === CampaignOngoingInterval.Flights) {
        if (!values.ongoing_flights) {
          errors.push('Schedule - ongoing flights are required');
        }
        for (const flight of values.ongoing_flights) {
          if (!flight.budget || !flight.start_date || !flight.end_date) {
            errors.push('Schedule - ongoing flights budget, start date and end date are required');
            break;
          }
        }
      }
    } else {
      if (!values.schedule_start_date) {
        errors.push('Schedule - start date is required');
      }
      if (!values.schedule_end_date) {
        errors.push('Schedule - end date is required');
      }
      if (!values.budget_amount) {
        errors.push('Schedule - budget is required');
      }
    }
    if (values.meta?.conversion_location_type === CampaignConversionLocationType.InstantForm) {
      if (!values.meta?.conversion_location?.instant_form?.form_name) {
        errors.push('Instant form - form name is required');
      }
      if (!values.meta?.conversion_location?.instant_form?.intro?.headline) {
        errors.push('Instant form - intro headline is required');
      }
      if (!values.meta?.conversion_location?.instant_form?.questions?.form_fields?.length) {
        errors.push('Instant form - questions form fields is required');
      }
      if (!values.meta?.conversion_location?.instant_form?.questions?.link_to_privacy_policy) {
        errors.push('Instant form - questions link to privacy policy is required');
      }
      if (!values.meta?.conversion_location?.instant_form?.ending?.headline) {
        errors.push('Instant form - ending headline is required');
      }
      if (!values.meta?.conversion_location?.instant_form?.ending?.description) {
        errors.push('Instant form - ending description is required');
      }
      if (!values.meta?.conversion_location?.instant_form?.ending?.action_button_link) {
        errors.push('Instant form - ending action button link is required');
      }
      if (!values.meta?.conversion_location?.instant_form?.ending?.action_button_display_text) {
        errors.push('Instant form - ending action button display text is required');
      }
    }
    if (values.meta?.conversion_location_type === CampaignConversionLocationType.Calls) {
      if (!values.meta?.conversion_location?.calls?.country_code) {
        errors.push('Calls - country code is required');
      }
      if (!values.meta?.conversion_location?.calls?.phone_number) {
        errors.push('Calls - phone number is required');
      }
    }
    if (errors.length > 0) {
      showErrorToast(errors);
      return false;
    }
    return true;
  };

  return (
    <>
      <Text size="xxl" weight={700}>
        Campaign Objective
      </Text>
      <Spacing size="xl" />
      <Text size="xl" weight={600}>
        {values.campaign_name}
      </Text>
      <Spacing size="xl" />
      <Form width="70%">
        <Flex direction="column" gap="xl">
          {values.channel === CampaignChannel.Meta && (
            <FormItem>
              <Text size="xl" weight={600}>
                Connect Meta Business ID
              </Text>
              <Spacing />
              <Flex gap="md" align="center">
                <Flex align="center">
                  <Text size="sm">Business ID or page URL</Text>
                  <Required />
                </Flex>
                <ControlledInput
                  name="meta.business_id"
                  control={control}
                  width="40rem"
                  placeholder="Enter business ID or page URL"
                />
              </Flex>
            </FormItem>
          )}
          <FormItem>
            <Text size="xl" weight={600}>
              Select KPI
            </Text>
            <Spacing />
            <Grid columns={3} gap="lg">
              {getCampaignObjectives().map((objective) => (
                <Type
                  key={objective.value}
                  active={objective.value === values.objective}
                  onClick={() => {
                    setValue('objective', objective.value);
                    setValue(
                      'budget_billing_type',
                      CAMPAIGN_OBJECTIVE_BILLING_TYPES[objective.value || CampaignObjective.Awareness][0],
                    );
                    if (objective.value !== CampaignObjective.Leads) {
                      setValue('meta.conversion_location_type', CampaignConversionLocationType.Website);
                    }
                  }}
                >
                  <Icon size="lg" type={objective.icon} />
                  <Text size="md" weight={500}>
                    {objective.title}
                  </Text>
                </Type>
              ))}
            </Grid>
            <Spacing />
            <Flex gap="md">
              <ControlledSelect
                name="budget_billing_type"
                control={control}
                options={CAMPAIGN_OBJECTIVE_BILLING_TYPES[values.objective || CampaignObjective.Awareness].map(
                  (billingType) => CAMPAIGN_BILLING_TYPE_OPTIONS.find((option) => option.value === billingType),
                )}
                width="25rem"
              />
              {[
                CampaignBillingType.CPV,
                CampaignBillingType.CPCV,
                CampaignBillingType.CPC,
                CampaignBillingType.CPA,
                CampaignBillingType.CPL,
                CampaignBillingType.CPCL,
                CampaignBillingType.MaxLeads,
                CampaignBillingType.MaxConversionLeads,
              ].includes(values.budget_billing_type) && (
                <ControlledInput
                  type="number"
                  prefix="$"
                  name="budget_billing_target"
                  control={control}
                  placeholder="KPI"
                  width="20rem"
                />
              )}
              {[CampaignBillingType.CTR, CampaignBillingType.ViewabilityRate, CampaignBillingType.VCR].includes(
                values.budget_billing_type,
              ) && (
                <ControlledInput
                  type="number"
                  suffix="%"
                  name="budget_billing_target"
                  control={control}
                  placeholder="KPI"
                  width="20rem"
                />
              )}
              {values.budget_billing_type === CampaignBillingType.ROI && (
                <Flex width="100%" gap="md">
                  <ControlledInput
                    suffix=": 1"
                    name="budget_billing_roi_avg_order_value"
                    control={control}
                    placeholder="ROI Amount"
                    width="20rem"
                  />
                  <ControlledInput
                    prefix="$"
                    name="budget_billing_target"
                    control={control}
                    placeholder="Average Order Value"
                    width="20rem"
                  />
                </Flex>
              )}
            </Flex>
          </FormItem>
          <FormItem>
            <Text size="xl" weight={600}>
              Schedule
            </Text>
            <Spacing size="lg" />
            <Text size="sm">
              <Flex direction="column" gap="md">
                <Flex gap="md" align="center">
                  <div>Is Ongoing?</div>
                  <ControlledSwitch name="ongoing_enabled" control={control} />
                </Flex>
                {values.ongoing_enabled ? (
                  <>
                    <Flex align="center" gap="md">
                      <Flex align="center">
                        <div>Ongoing Interval</div>
                        <Required />
                      </Flex>
                      <ControlledSelect
                        name="ongoing_interval"
                        control={control}
                        width="12rem"
                        options={CAMPAIGN_ONGOING_INTERVAL_OPTIONS}
                        placeholder=""
                      />
                      {[
                        CampaignOngoingInterval.Daily,
                        CampaignOngoingInterval.Weekly,
                        CampaignOngoingInterval.Monthly,
                      ].includes(values.ongoing_interval) && (
                        <>
                          <Flex align="center">
                            <div>Budget</div>
                            <Required />
                          </Flex>
                          <ControlledInput
                            prefix="$"
                            name="ongoing_interval_budget"
                            type="number"
                            control={control}
                            width="16rem"
                          />
                        </>
                      )}
                      {[
                        CampaignOngoingInterval.Weekly,
                        CampaignOngoingInterval.Monthly,
                        CampaignOngoingInterval.Flights,
                      ].includes(values.ongoing_interval) && (
                        <>
                          <div>Daily cap</div>
                          <ControlledInput
                            type="number"
                            prefix="$"
                            name="budget_amount_daily"
                            control={control}
                            width="10rem"
                          />
                        </>
                      )}
                      {values.channel !== CampaignChannel.Meta && (
                        <>
                          <div>Max Bid</div>
                          <ControlledInput
                            type="number"
                            prefix="$"
                            name="budget_max_bid_cpm"
                            control={control}
                            width="10rem"
                          />
                          <div>CPM</div>
                        </>
                      )}
                    </Flex>
                    {values.ongoing_interval === CampaignOngoingInterval.Flights && (
                      <>
                        <Flex align="center">
                          <Text size="sm">Ongoing Flights</Text>
                          <Required />
                        </Flex>
                        {values.ongoing_flights?.map((flight, index) => (
                          <Flex key={flight.id} align="center" gap="md">
                            <ControlledInput
                              prefix="$"
                              name={`ongoing_flights.${index}.budget`}
                              type="number"
                              control={control}
                              width="16rem"
                              placeholder="Budget"
                            />
                            <ControlledDatePicker
                              name={`ongoing_flights.${index}.start_date`}
                              control={control}
                              placeholder="Start date"
                              showTimeSelect
                              width="24rem"
                            />
                            <ControlledDatePicker
                              name={`ongoing_flights.${index}.end_date`}
                              control={control}
                              placeholder="End date"
                              showTimeSelect
                              width="24rem"
                            />
                            <Icon type="plus" color="primary" onClick={() => onAddOngoingFlight(index)} clickable />
                            {index !== 0 && (
                              <Icon type="trash" color="error" onClick={() => onRemoveOngoingFlight(index)} clickable />
                            )}
                          </Flex>
                        ))}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <Flex gap="md">
                      <FlexItem grow={1}>
                        <Flex direction="column" gap="md">
                          <Flex align="center">
                            <Text size="sm">When should this campaign start?</Text>
                            <Required />
                          </Flex>
                          <ControlledDatePicker
                            name="schedule_start_date"
                            control={control}
                            placeholder="Select start date"
                            showTimeSelect
                          />
                        </Flex>
                      </FlexItem>
                      <FlexItem grow={1}>
                        <Flex direction="column" gap="md">
                          <Flex align="center">
                            <Text size="sm">When should this campaign finish?</Text>
                            <Required />
                          </Flex>
                          <ControlledDatePicker
                            name="schedule_end_date"
                            control={control}
                            placeholder="Select end date"
                            showTimeSelect
                          />
                        </Flex>
                      </FlexItem>
                    </Flex>
                    <Flex align="center" gap="md">
                      <Flex align="center">
                        <div>Budget</div>
                        <Required />
                      </Flex>
                      <ControlledInput type="number" prefix="$" name="budget_amount" control={control} width="16rem" />
                      <div>Daily cap</div>
                      <ControlledInput
                        type="number"
                        prefix="$"
                        name="budget_amount_daily"
                        control={control}
                        width="10rem"
                      />
                      {values.channel !== CampaignChannel.Meta && (
                        <>
                          <div>Max Bid</div>
                          <ControlledInput
                            type="number"
                            prefix="$"
                            name="budget_max_bid_cpm"
                            control={control}
                            width="10rem"
                          />
                          <div>CPM</div>
                        </>
                      )}
                    </Flex>
                  </>
                )}
                <Text color="gray">Please note: The timezone is default to New York</Text>
                <ControlledRadioGroup name="budget_pacing" control={control} options={CAMPAIGN_PACING_OPTIONS} />
              </Flex>
            </Text>
          </FormItem>
          {values.channel !== CampaignChannel.Meta && (
            <FormItem>
              <Text size="xl" weight={600}>
                Frequency capping
              </Text>
              <Spacing />
              <Text size="sm">
                <Flex align="center" gap="sm">
                  <div>No more than</div>
                  <ControlledInput type="number" name="delivery_frequency_amount" control={control} width="10rem" />
                  <div>impression(s) per user every</div>
                  <ControlledSelect
                    name="delivery_frequency_interval"
                    options={CAMPAIGN_FREQUENCY_INTERVAL_OPTIONS}
                    control={control}
                    width="10rem"
                    placeholder=""
                  />
                </Flex>
              </Text>
            </FormItem>
          )}
          {values.channel === CampaignChannel.Meta && (
            <FormItem>
              <Text size="xl" weight={600}>
                Customize Placements
              </Text>
              <Spacing />
              <Flex gap="md" align="center">
                <Text size="sm">Placements</Text>
                <ControlledSelect
                  name="meta.customize_placements"
                  control={control}
                  options={CAMPAIGN_META_CUSTOMIZE_PLACEMENTS_OPTIONS}
                  multiple
                  width="40rem"
                  placeholder="Select placements"
                />
              </Flex>
            </FormItem>
          )}
          {values.channel === CampaignChannel.Meta && (
            <FormItem>
              <Text size="xl" weight={600}>
                Devices
              </Text>
              <Spacing />
              <Flex align="center" gap="md">
                <Text size="sm">Devices</Text>
                <ControlledSelect
                  width="20rem"
                  control={control}
                  name="meta.device"
                  options={CAMPAIGN_DEVICE_OPTIONS}
                />
              </Flex>
            </FormItem>
          )}
          {values.channel === CampaignChannel.Meta && values.objective === CampaignObjective.Leads && (
            <FormItem>
              <Text size="xl" weight={600}>
                Conversion Location
              </Text>
              <Spacing />
              <ControlledRadioGroup
                control={control}
                name="meta.conversion_location_type"
                options={CAMPAIGN_CONVERSION_LOCATION_TYPE_OPTIONS}
              />
            </FormItem>
          )}
          {(!values.meta?.conversion_location_type ||
            values.meta?.conversion_location_type === CampaignConversionLocationType.Website) && (
            <FormItem>
              <Text size="xl" weight={600}>
                Conversion Tracking
              </Text>
              <Spacing />
              <Text size="sm">What action do you want to track after a click?</Text>
              <Spacing />
              <Flex>
                <ControlledRadioGroup
                  name="engagement"
                  control={control}
                  options={CAMPAIGN_ENGAGEMENT_OPTIONS}
                  width="35rem"
                  onValueChange={(value) => {
                    if (value === CampaignEngagement.NoEngagement) {
                      setValue('pixel_uuid', null);
                    }
                  }}
                />
                <PixelSelect
                  agencyId={values.agency_id}
                  advertiserId={values.advertiser_id}
                  name="pixel_uuid"
                  control={control}
                  width="40rem"
                  multiple
                  onValueChange={(value) => {
                    setValue(
                      'engagement',
                      value.length > 0 ? CampaignEngagement.Pixel : CampaignEngagement.NoEngagement,
                    );
                  }}
                />
              </Flex>
            </FormItem>
          )}
          {values.meta?.conversion_location_type === CampaignConversionLocationType.InstantForm && (
            <FormItem>
              <Text size="xl" weight={600}>
                Instant Form
              </Text>
              <Spacing />
              <Flex align="center" gap="md">
                <Flex align="center">
                  <Text size="sm">Form name</Text>
                  <Required />
                </Flex>
                <ControlledInput
                  width="55rem"
                  control={control}
                  name="meta.conversion_location.instant_form.form_name"
                  placeholder="Enter form name"
                />
              </Flex>
              <Spacing />
              <Tabs
                value={instantFormTab}
                onChange={setInstantFormTab}
                tabs={[
                  {
                    label: 'Intro',
                    value: 'intro',
                  },
                  {
                    label: 'Questions',
                    value: 'questions',
                  },
                  {
                    label: 'Ending',
                    value: 'ending',
                  },
                ]}
              />
              <Spacing />
              {instantFormTab === 'intro' && (
                <Flex direction="column" gap="md">
                  <Text size="sm" weight={500}>
                    Background image
                  </Text>
                  <ControlledFile
                    width="55rem"
                    control={control}
                    name="meta.conversion_location.instant_form.intro.background_image"
                    notes={<Text size="xs">Recommended 1200 x 628 pixels</Text>}
                  />
                  <Flex align="center">
                    <Text size="sm" weight={500}>
                      Headline
                    </Text>
                    <Required />
                  </Flex>
                  <ControlledInput
                    width="55rem"
                    control={control}
                    name="meta.conversion_location.instant_form.intro.headline"
                    placeholder="Enter headline"
                  />
                  <Text size="sm" weight={500}>
                    Paragraph
                  </Text>
                  <ControlledTextArea
                    width="55rem"
                    control={control}
                    name="meta.conversion_location.instant_form.intro.paragraph"
                    placeholder="Enter paragraph"
                    rows={4}
                  />
                </Flex>
              )}
              {instantFormTab === 'questions' && (
                <Flex direction="column" gap="md">
                  <Text size="sm" weight={500}>
                    Questions
                  </Text>
                  {values.meta?.conversion_location?.instant_form?.questions?.questions?.length > 0 && (
                    <Flex direction="column" gap="sm">
                      {values.meta?.conversion_location?.instant_form?.questions?.questions?.map(
                        (question: any, index: number) => (
                          <Flex key={question.id} align="center" gap="md">
                            <Text size="sm" weight={500} width="2rem">
                              Q{index + 1}
                            </Text>
                            <ControlledInput
                              name={`meta.conversion_location.instant_form.questions.questions.${index}.title`}
                              control={control}
                              placeholder="Enter question"
                              width="40rem"
                            />
                            <Icon
                              type="trash"
                              color="error"
                              clickable
                              onClick={() => {
                                setValue(
                                  'meta.conversion_location.instant_form.questions.questions',
                                  values.meta?.conversion_location?.instant_form?.questions?.questions?.filter(
                                    (item: any) => item.id !== question.id,
                                  ),
                                );
                              }}
                            />
                          </Flex>
                        ),
                      )}
                    </Flex>
                  )}
                  <Button
                    width="14rem"
                    size="sm"
                    various="secondary"
                    onClick={() => {
                      setValue('meta.conversion_location.instant_form.questions.questions', [
                        ...(values.meta?.conversion_location?.instant_form?.questions?.questions || []),
                        { id: nanoid() },
                      ]);
                    }}
                  >
                    Add question
                  </Button>
                  <Flex align="center">
                    <Text size="sm" weight={500}>
                      Form fields
                    </Text>
                    <Required />
                  </Flex>
                  {values.meta?.conversion_location?.instant_form?.questions?.form_fields?.length > 0 && (
                    <Flex direction="column" gap="sm">
                      {values.meta?.conversion_location?.instant_form?.questions?.form_fields?.map(
                        (formField: any, index: number) => (
                          <Flex key={formField.id} align="center" gap="md">
                            <ControlledSelect
                              name={`meta.conversion_location.instant_form.questions.form_fields.${index}.key`}
                              control={control}
                              options={CAMPAIGN_META_INSTANT_FORM_FIELD_OPTIONS}
                              placeholder="Select form field"
                              width="40rem"
                            />
                            <Icon
                              type="trash"
                              color="error"
                              clickable
                              onClick={() => {
                                setValue(
                                  'meta.conversion_location.instant_form.questions.form_fields',
                                  values.meta?.conversion_location?.instant_form?.questions?.form_fields?.filter(
                                    (item: any) => item.id !== formField.id,
                                  ),
                                );
                              }}
                            />
                          </Flex>
                        ),
                      )}
                    </Flex>
                  )}
                  <Button
                    width="14rem"
                    size="sm"
                    various="secondary"
                    onClick={() => {
                      setValue('meta.conversion_location.instant_form.questions.form_fields', [
                        ...(values.meta?.conversion_location?.instant_form?.questions?.form_fields || []),
                        { id: nanoid() },
                      ]);
                    }}
                  >
                    Add form field
                  </Button>
                  <Flex align="center">
                    <Text size="sm" weight={500}>
                      Link to privacy policy
                    </Text>
                    <Required />
                  </Flex>
                  <ControlledInput
                    width="55rem"
                    control={control}
                    name="meta.conversion_location.instant_form.questions.link_to_privacy_policy"
                    placeholder="Enter link to privacy policy"
                  />
                </Flex>
              )}
              {instantFormTab === 'ending' && (
                <Flex direction="column" gap="md">
                  <Flex align="center">
                    <Text size="sm" weight={500}>
                      Headline
                    </Text>
                    <Required />
                  </Flex>
                  <ControlledInput
                    width="55rem"
                    control={control}
                    name="meta.conversion_location.instant_form.ending.headline"
                    placeholder="Enter headline"
                  />
                  <Flex align="center">
                    <Text size="sm" weight={500}>
                      Description
                    </Text>
                    <Required />
                  </Flex>
                  <ControlledTextArea
                    width="55rem"
                    control={control}
                    name="meta.conversion_location.instant_form.ending.description"
                    placeholder="Enter description"
                    rows={4}
                  />
                  <Flex align="center">
                    <Text size="sm" weight={500}>
                      Action button link
                    </Text>
                    <Required />
                  </Flex>
                  <ControlledInput
                    width="55rem"
                    control={control}
                    name="meta.conversion_location.instant_form.ending.action_button_link"
                    placeholder="Enter action button link"
                  />
                  <Flex align="center">
                    <Text size="sm" weight={500}>
                      Action button display text
                    </Text>
                    <Required />
                  </Flex>
                  <ControlledInput
                    width="55rem"
                    control={control}
                    name="meta.conversion_location.instant_form.ending.action_button_display_text"
                    placeholder="Enter action button display text"
                  />
                </Flex>
              )}
            </FormItem>
          )}
          {values.meta?.conversion_location_type === CampaignConversionLocationType.Calls && (
            <FormItem>
              <Text size="xl" weight={600}>
                Calls
              </Text>
              <Spacing />
              <Flex direction="column" gap="md">
                <Flex align="center" gap="sm">
                  <Flex align="center">
                    <Text size="sm">Call number</Text>
                    <Required />
                  </Flex>
                  <ControlledInput
                    width="16rem"
                    control={control}
                    name="meta.conversion_location.calls.country_code"
                    placeholder="Enter country code"
                  />
                  <ControlledInput
                    width="40rem"
                    control={control}
                    name="meta.conversion_location.calls.phone_number"
                    placeholder="Enter phone number"
                  />
                </Flex>
                <Flex align="center" gap="sm">
                  <Text size="sm">Enable callback requests</Text>
                  <ControlledSwitch control={control} name="meta.conversion_location_calls.enable_callback_requests" />
                </Flex>
              </Flex>
            </FormItem>
          )}
          <FormAction
            onBack={onBack}
            onSubmit={() => validate() && onNext()}
            submitText="NEXT"
            enableDraft={enableDraft}
            isSubmitting={formState.isSubmitting}
            onDraftSubmit={onDraftSubmit}
          />
        </Flex>
      </Form>
    </>
  );
};

const Type = styled.div<{ active?: boolean }>`
  padding: 2.4rem;
  border-radius: 0.6rem;
  font-size: 1.4rem;
  text-align: center;
  background: white;
  box-shadow: ${TOKENS.shadow.default};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  align-items: center;

  img {
    width: 7.5rem;
    height: 7.5rem;
  }

  svg {
    width: 4rem;
    height: 4rem;
  }

  ${(props) =>
    props.active
      ? css`
          border: solid 0.4rem ${TOKENS.color.primary};
        `
      : ''}
`;
