export const BEESWAX_BULK_UPLOAD_CREATE_CAMPAIGN_COLUMNS = [
  'Campaign ID',
  'Agency ID',
  'Advertiser ID',
  'Campaign Group',
  'Campaign Name',
  'Campaign Start Date',
  'Campaign End Date',
  'Custom Flight day',
  'Monthly Budget',
  'Daily Cap',
  'Frequency',
  'Max CPM',
  'Pacing',
  'Conversion Pixel',
  'Objective',
  'Audience Name',
  'Audience Targeting Pixel',
  'Channels',
  'Geo Targeting (country/state/city)',
  'Creative Start Dates',
  'Creative name',
  'Creative Sync Link',
  'Creative Click-Through URL',
  'New Creative Refresh Day',
];

export const BEESWAX_BULK_UPLOAD_UPDATE_CAMPAIGN_COLUMNS = [
  'Campaign ID',
  'Line Item ID',
  'Campaign Name',
  'Campaign Start Date',
  'Campaign End Date',
  'Campaign Budget',
  'Line Item Budget',
  'Flight Budget',
  'Daily Cap',
  'Frequency',
];

export const BEESWAX_BULK_UPLOAD_UPDATE_CREATIVE_COLUMNS = [
  'Line Item ID',
  'Campaign Name',
  'Creative Sync Link',
  'Advertiser Id',
  'Creative Name',
  'Creative Click Through Url',
  'Creative Start Date',
  'Creative End Date',
];
