import { Icon, IconProps } from 'src/components/Icon';
import { TOKENS } from 'src/design';
import { forwardRef, HTMLProps } from 'react';
import styled, { css } from 'styled-components';
import { Flex, FlexItem, Text } from 'src/components/Layout';
import { formatText } from 'src/utils';
import { Tag } from './Tag';

type InputVarious = 'default' | 'border' | 'pure';

const INPUT_VARIOUS_CSS = {
  default: css`
    padding: 1.2rem;
    border: solid 0.1rem ${TOKENS.color.grayLighter};
    border-radius: 1.2rem;
    box-shadow: ${TOKENS.shadow.default};
  `,
  border: css`
    padding: 1.2rem;
    border: solid 0.2rem ${TOKENS.color.grayLight};
    border-radius: 10rem;
    box-shadow: none;
  `,
  pure: css``,
};

export type InputItem = {
  label: string;
  value?: string | number;
};

export type InputProps = Omit<HTMLProps<HTMLInputElement>, 'as' | 'ref' | 'width'> & {
  various?: InputVarious;
  icon?: IconProps['type'];
  iconPosition?: 'left' | 'right';
  iconColor?: keyof typeof TOKENS.color;
  prefix?: string;
  suffix?: string;
  width?: string;
  items?: InputItem[];
  onItemRemove?: (item: InputItem) => void;
  onDeselectAll?: () => void;
};

export const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const {
    various = 'default',
    icon,
    iconPosition = 'left',
    iconColor = 'inputIcon',
    prefix,
    suffix,
    width,
    className,
    items,
    onItemRemove,
    onDeselectAll,
    ...inputProps
  } = props;

  return (
    <Container various={various} width={width} className={className} disabled={inputProps.disabled}>
      <Flex align="center" width="100%" gap="xs">
        {prefix && (
          <FlexItem shrink={0}>
            <Text size="sm">{prefix}</Text>
          </FlexItem>
        )}
        {icon && iconPosition === 'left' && <Icon type={icon} color={iconColor} size="sm" />}
        <Flex direction="column" gap="md" width="100%">
          {items && items.length > 0 && (
            <Flex direction="column" gap="sm">
              <Flex gap="xs" wrap="wrap">
                {items.map((item) => (
                  <Tag key={item.value} onClose={() => onItemRemove?.(item)}>
                    {formatText(item.label)}
                  </Tag>
                ))}
              </Flex>
              <Text color="error" weight={600} size="sm" clickable onClick={onDeselectAll}>
                Deselect all
              </Text>
            </Flex>
          )}
          <FlexItem grow={1}>
            <input className="data-hj-allow" ref={ref} {...inputProps} />
          </FlexItem>
        </Flex>
        {icon && iconPosition === 'right' && <Icon type={icon} color={iconColor} size="sm" />}
        {suffix && (
          <FlexItem shrink={0}>
            <Text size="sm">{suffix}</Text>
          </FlexItem>
        )}
      </Flex>
    </Container>
  );
});

const Container = styled.div<Pick<InputProps, 'various' | 'iconColor' | 'width' | 'disabled'>>`
  width: ${(props) => props.width ?? '100%'};
  box-sizing: border-box;
  background: ${(props) => (props.disabled ? TOKENS.color.bgGray : TOKENS.color.white)};
  ${(props) => INPUT_VARIOUS_CSS[props.various || 'default']}

  input {
    width: 100%;
    padding: 0 0.6rem;
    box-sizing: border-box;
    border: none;
    outline: none;
    max-width: 100%;

    :disabled {
      background: ${TOKENS.color.bgGray};
    }

    ::placeholder {
      color: ${TOKENS.color.gray};
    }
  }
`;
