import { TOKENS } from 'src/design';
import styled from 'styled-components';
import { CreativePreviewModal } from './CreativePreviewModal';
import { useState } from 'react';
import { Text } from 'src/components/Layout';
import { Action } from '../Form';
import { CREATIVE_IMAGE_PREVIEW_REG } from './constants';

type CreativePreviewNewProps = {
  fileUrl?: string;
  size?: string;
};

const CREATIVE_PREVIEW_DEFAULT_SIZE = '8rem';

function customEncodeURI(uri: string) {
  let encodedURI = encodeURI(uri);
  const charsToEncode = [
    {
      from: /@/g,
      to: '%40',
    },
    {
      from: /\?/g,
      to: '%3F',
    },
    {
      from: /=/g,
      to: '%3D',
    },
    {
      from: /#/g,
      to: '%23',
    },
    {
      from: /;/g,
      to: '%3B',
    },
    {
      from: /,/g,
      to: '%2C',
    },
    {
      from: /\$/g,
      to: '%24',
    },
    {
      from: /&/g,
      to: '%26',
    },
  ];
  charsToEncode.forEach(({ from, to }) => {
    encodedURI = encodedURI.replace(from, to);
  });
  return encodedURI;
}

export const CreativePreviewNew = (props: CreativePreviewNewProps) => {
  const { fileUrl, size = CREATIVE_PREVIEW_DEFAULT_SIZE } = props;

  const isDisplayImagePreview = CREATIVE_IMAGE_PREVIEW_REG.test(fileUrl);
  const [isOpen, setIsOpen] = useState<boolean>();

  if (!fileUrl) {
    return <Text>-</Text>;
  }

  const finalFileUrl = customEncodeURI(fileUrl);

  return (
    <>
      {isDisplayImagePreview ? (
        <CreativePreviewImage size={size} src={finalFileUrl} onClick={() => setIsOpen(true)} />
      ) : (
        <Action onClick={() => setIsOpen(true)}>Click to preview</Action>
      )}
      <CreativePreviewModal isOpen={isOpen} onClose={() => setIsOpen(false)} fileUrl={finalFileUrl} />
    </>
  );
};

const CreativePreviewImage = styled.img<{ size?: string }>`
  width: 100%;
  max-width: ${({ size }) => size};
  aspect-ratio: 1/1;
  object-fit: contain;
  background: ${TOKENS.color.bgGray};
  cursor: pointer;
`;
