import { Flex, Spacing } from 'src/components/Layout';
import { format } from 'date-fns';
import { TOKENS } from 'src/design';
import { sumBy } from 'lodash';
import { useForm } from 'react-hook-form';
import { growthApi } from 'src/services';
import styled from 'styled-components';
import { ReportingFormValues } from './Reporting';
import { useDataTable } from 'src/hooks';
import { ControlledInput } from 'src/components/Form';
import { DataTable, DataTableExport } from 'src/components/DataTable';
import { formatAmount, formatCount, formatDate } from 'src/utils';
import { ReportingInfo } from 'src/components/ReportingInfo';

const { useConversionQuery } = growthApi;

type ReportingConversionFormValues = {
  search?: string;
};

type ReportingConversionProps = ReportingFormValues;

export const ReportingConversion = (props: ReportingConversionProps) => {
  const { timeRange, dateFrom, dateTo, agencyId, advertiserId, campaignGroup, campaignId } = props;

  const isNotSelectAgencyAdvertiser = !agencyId || !advertiserId;

  const { data, isFetching, error } = useConversionQuery(
    {
      time_range: timeRange,
      date_from: dateFrom ? format(dateFrom, 'yyyy-MM-dd') : undefined,
      date_to: dateTo ? format(dateTo, 'yyyy-MM-dd') : undefined,
      agency_id: agencyId,
      advertiser_id: advertiserId,
      campaign_group: campaignGroup,
      campaign_id: campaignId,
    },
    { skip: isNotSelectAgencyAdvertiser },
  );

  const { watch, control } = useForm<ReportingConversionFormValues>();

  const values = watch();

  const { dataTableProps, dataTableExportProps } = useDataTable({
    name: 'reporting-conversion',
    data: data?.data,
    isLoading: isFetching,
    error,
    search: values.search,
    searchKeys: ['domain', 'conversion_tracker', 'campaign_name'],
    defaultSort: {
      key: 'impressions',
      direction: 'desc',
    },
    sortNumberKeys: ['impressions', 'clicks', 'total_spend'],
    enableTotal: true,
    columns: [
      {
        header: 'Campaign Name',
        accessor: 'campaign_name',
        sortable: true,
        totalRender: () => {
          return 'Total';
        },
      },
      {
        header: 'Impressions',
        accessor: 'impressions',
        sortable: true,
        render: formatCount,
        totalRender: (data) => {
          return formatCount(sumBy(data, 'impressions'));
        },
      },
      {
        header: 'Clicks',
        accessor: 'clicks',
        sortable: true,
        render: formatCount,
        totalRender: (data) => {
          return formatCount(sumBy(data, 'clicks'));
        },
      },
      {
        header: 'Domain',
        accessor: 'domain',
        sortable: true,
      },
      {
        header: 'Tracker',
        accessor: 'conversion_tracker',
        sortable: true,
      },
      {
        header: 'Device',
        accessor: 'device',
        sortable: true,
      },
      {
        header: 'Conversion Time',
        accessor: 'conversion_time',
        render: formatDate,
        sortable: true,
      },
      {
        header: 'Transaction ID',
        accessor: 'transaction_id',
        sortable: true,
      },
      {
        header: 'Revenue',
        accessor: 'total_spend',
        sortable: true,
        render: formatAmount,
        totalRender: (data) => {
          return formatAmount(sumBy(data, 'total_spend'));
        },
      },
    ],
  });

  if (isNotSelectAgencyAdvertiser) {
    return <ReportingInfo message="Please select agency and advertiser to see the report" />;
  }

  return (
    <>
      <Spacing size="lg" />
      <TableContainer>
        <Flex gap="lg" justify="space-between" align="center">
          <ControlledInput name="search" control={control} prefix="Search:" placeholder="Keyword" width="30rem" />
          <DataTableExport {...dataTableExportProps} />
        </Flex>
        <Spacing size="lg" />
        <DataTable {...dataTableProps} />
      </TableContainer>
    </>
  );
};

const TableContainer = styled.div`
  background: white;
  padding: 2.4rem;
  box-shadow: ${TOKENS.shadow.default};
  border-radius: 1rem;
`;
